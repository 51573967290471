// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Terms_App__KSCLO {
    text-align: center;
  }
  
  .Terms_App-logo__pvr5T {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .Terms_App-logo__pvr5T {
      animation: Terms_App-logo-spin__yV6Sf infinite 20s linear;
    }
  }
  
  .Terms_Header__4YmZq {
    background-color: #fcfbf8;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .Terms_App-link__CoO7\\+ {
    color: #61dafb;
  }
  
  .Terms_Title__GN0o0 {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    font-family: "Rubik";
    color:black;
    margin-top: 20px;
  }
  
  @keyframes Terms_App-logo-spin__yV6Sf {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Terms/Terms.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,oBAAoB;EACtB;;EAEA;IACE;MACE,yDAA4C;IAC9C;EACF;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;EACd;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,oBAAoB;IACpB,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".App {\n    text-align: center;\n  }\n  \n  .App-logo {\n    height: 40vmin;\n    pointer-events: none;\n  }\n  \n  @media (prefers-reduced-motion: no-preference) {\n    .App-logo {\n      animation: App-logo-spin infinite 20s linear;\n    }\n  }\n  \n  .Header {\n    background-color: #fcfbf8;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    color: white;\n  }\n  \n  .App-link {\n    color: #61dafb;\n  }\n  \n  .Title {\n    font-weight: 700;\n    font-size: 26px;\n    line-height: 30px;\n    font-family: \"Rubik\";\n    color:black;\n    margin-top: 20px;\n  }\n  \n  @keyframes App-logo-spin {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `Terms_App__KSCLO`,
	"App-logo": `Terms_App-logo__pvr5T`,
	"App-logo-spin": `Terms_App-logo-spin__yV6Sf`,
	"Header": `Terms_Header__4YmZq`,
	"App-link": `Terms_App-link__CoO7+`,
	"Title": `Terms_Title__GN0o0`
};
export default ___CSS_LOADER_EXPORT___;
