import React from "react";
import styles from "./TopBarHome.module.css";
import Image from "../../Images/Sus-a-bite-White.png";
import { Link } from "react-router-dom";

function TopBarHome() {
  return (
   <div className={styles.Main}>
      <Link to="/">
        <img src={Image} alt="S_Logo" className={styles.Image} />
      </Link>
   </div>
  );
}

export default TopBarHome;