import React from "react";
import styles from "./Loading.module.css";
import { Spinner } from "react-activity";


function Loading() {
  return (
   <div className={styles.Main}>
        <Spinner 
            color='#FF6EC7'
            size='30'
        />
   </div>
  );
}

export default Loading;
