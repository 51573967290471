// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TopBarHome_Main__\\+UCZO {
    display: flex;
    background: black;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 60px;
}
.TopBarHome_Image__S0mxx {
    resize: both;
    width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/Components/TopBarHome/TopBarHome.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;AAChB;AACA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".Main {\n    display: flex;\n    background: black;\n    flex-wrap: nowrap;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    height: 60px;\n}\n.Image {\n    resize: both;\n    width: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Main": `TopBarHome_Main__+UCZO`,
	"Image": `TopBarHome_Image__S0mxx`
};
export default ___CSS_LOADER_EXPORT___;
