import React from 'react';
import styles from './TopBarDashboard.module.css';
import ImageSettings from "../../Images/Settings.png";
import RedPin from "../../Images/RedPinEmoji.png";

const TopBarDashboard = ({handleLeftButtonClick, restaurantSuburb, restaurantName}) => {
  return (
    <div className={styles.Topbar}>
       <img
        className={styles.MobileButton}
        src={ImageSettings}
        alt="Mobile Button"
        onClick={handleLeftButtonClick}
      />
      <div
        className={styles.LocationButton}
      >
        <span
          className={styles.NameText}
        >
          {restaurantName}
        </span>
        <span
          className={styles.LocationText}
        >
          <img
            className={styles.PinEmoji}
            src={RedPin}
            alt="PinEmoji"
          />
          {restaurantSuburb}
        </span>
      </div>
      {/* Right button (always visible) */}
      {/* <img
        className={styles.BellButton}
        src={ImageBell}
        alt="Always Visible Button"
        onClick={handleRightButtonClick}
      /> */}
    </div>
  );
};

export default TopBarDashboard;
