import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Home from "./Pages/Home/Home";
import Terms from "./Pages/Terms/Terms";
import Privacy from "./Pages/Privacy/Privacy";
import ContactPrivacy from "./Pages/ContactPrivacy/ContactPrivacy";
import SignUp from "./Pages/SignUp/SignUp";
import MyMarket from "./Pages/MyMarket/MyMarket";
import MyMarketRegister from "./Pages/MyMarketRegister/MyMarketRegister";
import MyMarketStorePicker from "./Pages/MyMarketStorePicker/MyMarketStorePicker";
import MyMarketDashboard from "./Pages/MyMarketDashboard/MyMarketDashboard";
import MyMarketTag from "./Pages/MyMarketTag/MyMarketTag";
import SidebarDashboard from "./Pages/SidebarDashboard/SidebarDashboard";
import SidebarSettings from "./Pages/SidebarSettings/SidebarSettings";
import SidebarStore from "./Pages/SidebarStore/SidebarStore";
import SidebarHelp from "./Pages/SidebarHelp/SidebarHelp";
import SidebarCoupon from "./Pages/SidebarCoupon/SidebarCoupon";
// import { getToken } from "./Helpers/Helpers";

function App() {
  return (
    <Router>
       <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/ContactPrivacy" element={<ContactPrivacy />} />
        <Route path="/MyMarket" element={<MyMarket />} />
        <Route path="/Contact" element={<SignUp />} />
        <Route path="/MyMarketRegister" element={<MyMarketRegister />} />
        <Route 
          path="/MyMarketDashboard/:id/:storeId" 
          element={<MyMarketDashboard />} 
          // element={getToken() ? <MyMarketDashboard /> : <Navigate to="/MyMarket" />}
        >
          <Route 
            path={`Coupon`}
            element={<SidebarCoupon />}
            // element={getToken() ? <SidebarSurpriseBag /> : <Navigate to="/MyMarket" />}
          />
          <Route 
            path={`Dashboard`}
            element={<SidebarDashboard />}
            // element={getToken() ? <SidebarDashboard /> : <Navigate to="/MyMarket" />}
          />
          <Route 
            path={`Store`}  
            element={<SidebarStore />}
            // element={getToken() ? <SidebarSettings /> : <Navigate to="/MyMarket" />}
          />
          <Route 
            path={`Settings`}  
            element={<SidebarSettings />}
            // element={getToken() ? <SidebarSettings /> : <Navigate to="/MyMarket" />}
          />
          <Route 
            path={`Help`} 
            element={<SidebarHelp />}
            // element={getToken() ? <SidebarHelp /> : <Navigate to="/MyMarket" />}
          />
        </Route>
        <Route 
          path="/MyMarketTag/:id"  
          element={<MyMarketTag />}
          // element={getToken() ? <MyMarketTag /> : <Navigate to="/MyMarketTag" />}
        />
        <Route 
          path="/MyMarketStorePicker/:id" 
          element={<MyMarketStorePicker />}
          // element={getToken() ? <MyMarketStorePicker/> : <Navigate to="/MyMarketRegister" />}
        />
        <Route path="*" element={<Home />} />
      </Routes>
  </Router>
  );
}

export default App;
