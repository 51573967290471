import React from "react";
import styles from "./HomePageOG.module.css";


function HomePageOG() {
  return (
   <div className={styles.Container}>
      <div
        className={styles.BottomBar}
      />

      <div
        className={styles.Main}
      >


      </div>
      <div
        className={styles.BottomBar}
      >
         <a className={styles.TextBottomBar} href="mailto:nicolas@susabite.co" target="_blank" rel="noopener noreferrer">
            Contact
        </a>
        <a className={styles.TextBottomBar1} href="https://typhoon-billboard-fd4.notion.site/Partner-with-Susabite-4de8ecb8952a4bac83bc1ade21c74a12?pvs=4" target="_blank" rel="noopener noreferrer">
            For Restaurants
        </a>
      </div>
   </div>
  );
}

export default HomePageOG;