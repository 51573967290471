import React from "react";
import styles from "./StoreTagMiddle.module.css";

function StoreTagMiddle({Description, Name, Address}) {
  return (
   <div className={styles.Main}>
        <div
            className={styles.TopContainer}
        >
            <span
                className={styles.TextTitle}
            >
                Name
            </span>
            <span
                className={styles.TextInfo}
            >
                {Name}
            </span>
        </div>
        <div
            className={styles.TopContainer}
        >
            <span
                className={styles.TextTitle}
            >
                Address
            </span>
            <span
                className={styles.TextInfo}
            >
                {Address}
            </span>
        </div>
        <div
            className={styles.BottomContainer}
        >
            <span
                className={styles.TextTitle}
            >
                Description
            </span>
            <span
                className={styles.TextInfo}
            >
                {Description}
            </span>
        </div>
        <div
            className={styles.QuestionContainer}
        >
            <span className={styles.TextDiv2Black}>
                Store Type(s)
            </span>
            <span className={styles.TextDiv1Gray}>
                Choose minimum 1, maximum 3 that fit best.
            </span>
        </div>
   </div>
  );
}

export default StoreTagMiddle;