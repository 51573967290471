// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactPrivacy_App__K\\+tty {
    text-align: center;
  }
  
  .ContactPrivacy_App-logo__vgbtV {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .ContactPrivacy_App-logo__vgbtV {
      animation: ContactPrivacy_App-logo-spin__CTrE7 infinite 20s linear;
    }
  }
  
  .ContactPrivacy_Header__P-OON {
    background-color: #fcfbf8;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .ContactPrivacy_App-link__HhDJe {
    color: #61dafb;
  }
  
  .ContactPrivacy_Title__VFjBF {
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    font-family: "Rubik";
    color:black;
    margin-top: 20px;
  }
  
  @keyframes ContactPrivacy_App-logo-spin__CTrE7 {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/Pages/ContactPrivacy/ContactPrivacy.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;;EAEA;IACE,cAAc;IACd,oBAAoB;EACtB;;EAEA;IACE;MACE,kEAA4C;IAC9C;EACF;;EAEA;IACE,yBAAyB;IACzB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;EACd;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,oBAAoB;IACpB,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".App {\n    text-align: center;\n  }\n  \n  .App-logo {\n    height: 40vmin;\n    pointer-events: none;\n  }\n  \n  @media (prefers-reduced-motion: no-preference) {\n    .App-logo {\n      animation: App-logo-spin infinite 20s linear;\n    }\n  }\n  \n  .Header {\n    background-color: #fcfbf8;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 2vmin);\n    color: white;\n  }\n  \n  .App-link {\n    color: #61dafb;\n  }\n  \n  .Title {\n    font-weight: 700;\n    font-size: 26px;\n    line-height: 30px;\n    font-family: \"Rubik\";\n    color:black;\n    margin-top: 20px;\n  }\n  \n  @keyframes App-logo-spin {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `ContactPrivacy_App__K+tty`,
	"App-logo": `ContactPrivacy_App-logo__vgbtV`,
	"App-logo-spin": `ContactPrivacy_App-logo-spin__CTrE7`,
	"Header": `ContactPrivacy_Header__P-OON`,
	"App-link": `ContactPrivacy_App-link__HhDJe`,
	"Title": `ContactPrivacy_Title__VFjBF`
};
export default ___CSS_LOADER_EXPORT___;
