import React, {useState, useEffect} from 'react';
import styles from './SidebarDashboard.module.css';
import { useOutletContext } from "react-router-dom";
import { useAuthContext } from "../../Context/AuthContext";
import { Link } from 'react-router-dom';
import Todo from "../../Images/Todo.png";
import Sales from "../../Images/Sales.png";
import Calendar from "../../Images/Calendar.png";
import Coupon from "../../Images/Coupon.png";
import Dashboard from "../../Images/Dashboard.png";
import ClapperBoard from "../../Images/ClapperBoard.png";
import CheckMark from "../../Images/CheckMark.png";
import StopWatch from "../../Images/StopWatch.png";
import Receipt from "../../Images/ReceiptEmoji.png";
import Switch from 'react-switch';
import { message } from "antd";
import axios from 'axios';
import { getToken } from "../../Helpers/Helpers";
import { API_BASE_URL} from "../../Config/Config";
import { Spinner } from "react-activity";
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome CSS

function SidebarDashboard() {
const [isAnswerVisible1, setIsAnswerVisible1] = useState(false);
const [isAnswerVisible2, setIsAnswerVisible2] = useState(false);
const [isAnswerVisible3, setIsAnswerVisible3] = useState(false);
const [isAnswerVisible4, setIsAnswerVisible4] = useState(false);
const [loading, setLoading] = useState(true);
const [itemId, setItemId] = useState(null);
const [isModal, setModal] = useState(false);
const [isModalCloseShop, setModalCloseShop] = useState(false);
const [checkSwitch, setCheckSwitch] = useState([]);
const [orderData, setOrderData] = useState([]);

const { user } = useAuthContext();
const { restaurantData, coupons, handleSetSidebar } = useOutletContext(); // <-- access context value

// useEffect(() => {
//     getOrders()
// }, []);

const getOrders = async() => {
    setLoading(true)
    axios
    .get(`${API_BASE_URL}api/get-orders-restaurant`, 
    {headers: {
        'Authorization': `Bearer ${getToken()}`}}        
    )
    .then(response => {
      console.log('order loaded', response.data.data)
      setOrderData(response.data.data)
      setLoading(false)
    })
    .catch(error => {
        console.log('error', error)
        setLoading(false)
    });  
}

const handleReload = async() => {
    getOrders()
}

const handleCancel = async(item) => {
    setItemId(item.id)
    setModal(true)
};

const handleBack = async() => {
    setItemId(null)
    setModal(false)
};

const handleBackCloseShop = async() => {
    setModalCloseShop(false)
};

const handleCloseShop = async () => {
    if (checkSwitch === true){
        axios
            .post(`${API_BASE_URL}api/update-sold-out-info`, {
            "data": 'close',
            },{headers: {
                'Authorization': `Bearer ${getToken()}` }}        
            )
            .then(response => {
                setCheckSwitch(false)
                setModalCloseShop(false)
                message.success("Changes status to 'CLOSED'");
            })
            .catch(error => {
                console.log('error', error)
                getOrders()
                message.success("Failed to changed status");
            }); 
    }else{
        message.success("Restaurant is already closed");
    }
}

const handleModalCloseShop = async() => {
    setModalCloseShop(true)
};

const handleCancel2 = async() => {
    axios
    .post(`${API_BASE_URL}api/cancel-order-restaurant`, {
      "id": itemId,
    },{headers: {
        'Authorization': `Bearer ${getToken()}` }}        
    )
    .then(response => {
        const updatedOrderData = orderData.map(order => {
            if (order.id === itemId) {
                return { ...order, orderStatus: 'cancelled' };
            }
            return order;
        });
        setOrderData(updatedOrderData);
        setItemId(null)
        setModal(false)
        message.success("Order Cancelled");
    })
    .catch(error => {
        console.log('error', error)
        getOrders()
        message.success("Failed to cancel order");
    }); 

};

const toggleAnswer1 = () => {
        setIsAnswerVisible1(!isAnswerVisible1);
};

const toggleAnswer2 = () => {
        setIsAnswerVisible2(!isAnswerVisible2);
};

const toggleAnswer3 = () => {
        setIsAnswerVisible3(!isAnswerVisible3);
};

const toggleAnswer4 = () => {
        setIsAnswerVisible4(!isAnswerVisible4);
};

    
return (
    <div
        className={styles.Main}
    >
        <div
            className={styles.TopContainer}
        >
            <span
                className={styles.TopContainerText}
            >
                G'day {restaurantData.restaurantPublic.restaurantName}
            </span>
        </div>
        {coupons && coupons.length > 0 && (coupons[0].isCouponActive === 'Active' || coupons[0].isCouponActive === 'UnderReview')?
            <div className={styles.DashboardInfoContainer2}>
                <img src={Dashboard} alt="Dashboard_Icon" className={styles.DashboardInfoIconImage} />
                <span className={styles.DashboardInfoContainerText2}>
                        We will Activate your account in 24 hours!
                </span>
            </div>
         :
                <div className={styles.DashboardInfoContainer}>
                    <img src={Dashboard} alt="Dashboard_Icon" className={styles.DashboardInfoIconImage} />
                    <span className={styles.DashboardInfoContainerText}>
                        The dashboard will appear here once you have finished the to-do list.
                    </span>
                </div>
        }
        {restaurantData.restaurantPublic.isRestaurantConfirmed === true ? (
            <>
                
            </>
        ):(
            <>
            <div
                className={styles.TodoContainer}
            >
                <div
                    className={styles.TodoIcon}
                >
                    <img src={Todo} alt="Todo_Icon" className={styles.TodoIconImage} />
                    <span
                        className={styles.TodoIconText}
                    >
                        To-do
                    </span>
                </div>
                <span
                    className={styles.TodoContainerText}
                >
                    Complete the following tasks to start using SusaBite
                </span>
                <div
                    className={styles.ButtonContainers}
                >
                    {coupons && coupons.length > 0 ?
                        <Link
                            to={`/MyMarketDashboard/${user.id}/${restaurantData.restaurantPublic.id}/Coupon`}
                            onClick={handleSetSidebar}
                            className={styles.ButtonContainerCheck} 
                        >
                            <img src={CheckMark} alt="Todo_Icon" className={styles.ButtonContainerImage} />
                            <span className={styles.TextButtonCheck}>
                                Coupon Created!
                            </span>
                        </Link>
                        :
                        <Link
                            to={`/MyMarketDashboard/${user.id}/${restaurantData.restaurantPublic.id}/Coupon`}
                            onClick={handleSetSidebar}
                            className={styles.ButtonContainer} 
                        >
                            <img src={Coupon} alt="Todo_Icon" className={styles.ButtonContainerImage} />
                            <span className={styles.TextButton}>
                                Create Your First Coupon
                            </span>
                        </Link>
                    }
                    {coupons && coupons.length > 0 && (coupons[0].isCouponActive === 'Active' || coupons[0].isCouponActive === 'UnderReview') ?
                        <Link
                            to={`/MyMarketDashboard/${user.id}/${restaurantData.restaurantPublic.id}/Coupon`}
                            onClick={handleSetSidebar}
                            className={styles.ButtonContainerCheck} 
                        >
                            <img src={CheckMark} alt="Todo_Icon" className={styles.ButtonContainerImage} />
                            <span className={styles.TextButtonCheck}>
                                Coupon Activated!
                            </span>
                        </Link>
                        :
                        <Link
                            to={`/MyMarketDashboard/${user.id}/${restaurantData.restaurantPublic.id}/Coupon`}
                            onClick={handleSetSidebar}
                            className={styles.ButtonContainer} 
                        >
                            <img src={Coupon} alt="Todo_Icon" className={styles.ButtonContainerImage} />
                            <span className={styles.TextButton}>
                                Activate Your First Coupon
                            </span>
                        </Link>
                    }
                </div>
            </div>
            {/* <div
                className={styles.DashboardInfoContainer}
            >
                <img src={ClapperBoard} alt="Dashboard_Icon" className={styles.DashboardHelpIconImage} />
                <span
                    className={styles.DashboardHelpContainerText}
                >
                    Need Help with the To-do's? <a className={styles.DashboardHelpContainerTextBig} href="mailto:nicolas@sustainabite.com.au" target="_blank" rel="noopener noreferrer">Watch our video guide</a>
                </span>
            </div> */}
            <div
                className={styles.DashboardInfoContainer}
            >
                <img src={Dashboard} alt="Dashboard_Icon" className={styles.DashboardHelpIconImage} />
                <span
                    className={styles.DashboardHelpContainerText}
                >
                    <span className={styles.DashboardHelpContainerTextBig}>Need more Help?</span> contact us at <span className={styles.DashboardHelpContainerTextBig}>+61480543610</span>
                </span>
            </div>
            </>
        )}
    </div>
);
}

export default SidebarDashboard;