import React from "react";
import styles from "./HomePageP4.module.css";

function HomePageP4() {
     return (
          <div className={styles.Main}>
               <span className={styles.Div1AText}>
                    Ready to Simplify Salary Packaging?
               </span>
               <span className={styles.Div1BText}>
                    Start saving time and money today with SalaryPie
               </span>
            <div className={styles.Div2}>
              <a 
                  className={styles.DivContact}
                  href="https://calendly.com/salary-pie" // Replace with your Calendly URL
                  target="_blank" 
                  rel="noopener noreferrer" 
              >
                  <span className={styles.TextDivContact}>
                      Get Started Today
                  </span>
              </a>
            </div>
          </div>
        );
      };

export default HomePageP4;