// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_Main__uaU4x {
    display: flex;
    /* background: white; */
    background: #fcfbf8;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}
.Loading_Div1__k1jo2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    flex: 1 1;
}
.Loading_TextDiv1Black__jLqbb {
    font-weight: 500;
    font-size: 42px;
    font-family: "Rubik";
    color:black;
    margin-left: 80px;
    margin-right: 80px;
    max-width: 650px;
    line-height: 90px;
}
.Loading_TextDiv1Pink__LF5-F {
    font-weight: 700;
    font-size: 52px;
    font-family: "Rubik";
    color:#FF6EC7;
}

@media only screen and (max-width: 600px) {
    .Loading_TextDiv1Black__jLqbb {
        font-weight: 500;
        font-size: 30px;
        font-family: "Rubik";
        color:black;
        margin-left: 30px;
        margin-right: 30px;
        line-height: 45px;
    }
    .Loading_TextDiv1Pink__LF5-F {
        font-weight: 700;
        font-size: 35px;
        font-family: "Rubik";
        color:#FF6EC7;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Loading/Loading.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,WAAW;IACX,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;IACZ,SAAO;AACX;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,aAAa;AACjB;;AAEA;IACI;QACI,gBAAgB;QAChB,eAAe;QACf,oBAAoB;QACpB,WAAW;QACX,iBAAiB;QACjB,kBAAkB;QAClB,iBAAiB;IACrB;IACA;QACI,gBAAgB;QAChB,eAAe;QACf,oBAAoB;QACpB,aAAa;IACjB;AACJ","sourcesContent":[".Main {\n    display: flex;\n    /* background: white; */\n    background: #fcfbf8;\n    flex-wrap: nowrap;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    width: 100%;\n    height: 100vh;\n}\n.Div1 {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    width: 100vw;\n    flex: 1;\n}\n.TextDiv1Black {\n    font-weight: 500;\n    font-size: 42px;\n    font-family: \"Rubik\";\n    color:black;\n    margin-left: 80px;\n    margin-right: 80px;\n    max-width: 650px;\n    line-height: 90px;\n}\n.TextDiv1Pink {\n    font-weight: 700;\n    font-size: 52px;\n    font-family: \"Rubik\";\n    color:#FF6EC7;\n}\n\n@media only screen and (max-width: 600px) {\n    .TextDiv1Black {\n        font-weight: 500;\n        font-size: 30px;\n        font-family: \"Rubik\";\n        color:black;\n        margin-left: 30px;\n        margin-right: 30px;\n        line-height: 45px;\n    }\n    .TextDiv1Pink {\n        font-weight: 700;\n        font-size: 35px;\n        font-family: \"Rubik\";\n        color:#FF6EC7;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Main": `Loading_Main__uaU4x`,
	"Div1": `Loading_Div1__k1jo2`,
	"TextDiv1Black": `Loading_TextDiv1Black__jLqbb`,
	"TextDiv1Pink": `Loading_TextDiv1Pink__LF5-F`
};
export default ___CSS_LOADER_EXPORT___;
