import React, {useState} from "react";
import styles from'./HomePageP5.module.css'; 
import LogoWhiteSVG from "../../Images/LogoWhiteSVG.svg";  // Add this import
import LogoTitleWhiteSVG from "../../Images/LogoTitleWhiteSVG.svg";// You'll need to create a CSS f



function HomePageP5() {
     return (
          <div className={styles.Main}>
               <div className={styles.Div1}>
                    <img src={LogoWhiteSVG} alt="LogoWhite" className={styles.Logo} />
                    <img src={LogoTitleWhiteSVG} alt="LogoTitleWhite" className={styles.LogoTitle} />
                </div>
                <div className={styles.Div2}>
                <span className={styles.TextDiv2}>
                    Privacy Policy | Terms & Conditions 
                </span>
                </div>
                <div className={styles.Div3}>
                    <span className={styles.TextDiv3}>
                        SalaryPie © 2025. Simplifying salary packaging for <br/>FBT-exempt organizations.
                    </span>
                </div>
          </div>
        );
      };

export default HomePageP5;

