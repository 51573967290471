// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dashboard.module.css */
.MyMarketDashboard_Dashboard__QYiI- {
    display: flex;
}
  
.MyMarketDashboard_Content__NhIAx {
    margin-left: 270px; /* Same as sidebar width */
    width: 100%;
    height: calc(100vh - 50px);
    align-items: center;
    background-color: #fcfbf8;
    margin-top: 50px;
    overflow-y: scroll; /* Enable vertical scrolling */
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .MyMarketDashboard_Content__NhIAx {
        margin-left: 0px; /* Same as sidebar width */
        width: 100vw;
        height: calc(100vh - 50px);
        align-items: center;
        background-color: #fcfbf8;
        margin-top: 50px;
        overflow-y: scroll; /* Enable vertical scrolling */
        justify-content: center;
    }
}`, "",{"version":3,"sources":["webpack://./src/Pages/MyMarketDashboard/MyMarketDashboard.module.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB,EAAE,0BAA0B;IAC9C,WAAW;IACX,0BAA0B;IAC1B,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB,EAAE,8BAA8B;IAClD,uBAAuB;AAC3B;;AAEA;IACI;QACI,gBAAgB,EAAE,0BAA0B;QAC5C,YAAY;QACZ,0BAA0B;QAC1B,mBAAmB;QACnB,yBAAyB;QACzB,gBAAgB;QAChB,kBAAkB,EAAE,8BAA8B;QAClD,uBAAuB;IAC3B;AACJ","sourcesContent":["/* Dashboard.module.css */\n.Dashboard {\n    display: flex;\n}\n  \n.Content {\n    margin-left: 270px; /* Same as sidebar width */\n    width: 100%;\n    height: calc(100vh - 50px);\n    align-items: center;\n    background-color: #fcfbf8;\n    margin-top: 50px;\n    overflow-y: scroll; /* Enable vertical scrolling */\n    justify-content: center;\n}\n\n@media screen and (max-width: 768px) {\n    .Content {\n        margin-left: 0px; /* Same as sidebar width */\n        width: 100vw;\n        height: calc(100vh - 50px);\n        align-items: center;\n        background-color: #fcfbf8;\n        margin-top: 50px;\n        overflow-y: scroll; /* Enable vertical scrolling */\n        justify-content: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Dashboard": `MyMarketDashboard_Dashboard__QYiI-`,
	"Content": `MyMarketDashboard_Content__NhIAx`
};
export default ___CSS_LOADER_EXPORT___;
