import React from "react";
import styles from "./PrivacyMiddle.module.css";
import Image from "../../Images/OpenBookEmoji.png";


function PrivacyMiddle() {
  return (
   <div 
        className={styles.Main}
    >
       <div
            className={styles.TermsContainer}
       >
            <div
                className={styles.TitleContainer}
            >
                <img src={Image} alt="BookEmoji" className={styles.Image} />
                <text
                    className={styles.TitleText}
                >
                    Privacy Policy
                </text>
            </div>
            <div
                className={styles.InfoContainer}
            >
                <h2 className={styles.sectionHeader}>1. Introduction</h2>
                <p className={styles.paragraph}>
                    This Privacy Policy ("Policy") outlines how Sustainabite ("we," "our," or "us") collects, uses, discloses, and protects the personal information of users ("you" or "your") of our website and services.
                </p>

                <h2 className={styles.sectionHeader}>2. Information We Collect</h2>
                <p className={styles.paragraph}>
                    We may collect the following types of personal information:
                </p>
                <ul className={styles.paragraph}>
                    <li>Contact Information: This may include your name, email address, phone number, and postal address.</li>
                    <li>Account Information: Information related to your Sustainabite account, including your username and password.</li>
                    <li>Transaction Information: Details of transactions conducted through our app, including purchase history.</li>
                    <li>Usage Data: Information about your interaction with our website and app, such as IP address, device information, and browsing history.</li>
                    <li>Feedback: Any feedback or communications you provide to us.</li>
                </ul>

                <h2 className={styles.sectionHeader}>3. How We Use Your Information</h2>
                <p className={styles.paragraph}>
                    We use your personal information for the following purposes:
                </p>
                <ul className={styles.paragraph}>
                    <li>To provide and maintain our Services.</li>
                    <li>To communicate with you about your account, transactions, and updates.</li>
                    <li>To improve and customize our Services.</li>
                    <li>To analyze usage patterns and trends.</li>
                    <li>To respond to your inquiries and requests.</li>
                    <li>To comply with legal and regulatory requirements.</li>
                </ul>

                <h2 className={styles.sectionHeader}>4. Data Security</h2>
                <p className={styles.paragraph}>
                    We take reasonable steps to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no data transmission over the internet or method of storage is 100% secure, and we cannot guarantee absolute security.
                </p>

                <h2 className={styles.sectionHeader}>5. Data Sharing</h2>
                <p className={styles.paragraph}>
                    We do not share your personal information with third parties except as follows:
                </p>
                <ul className={styles.paragraph}>
                    <li>With your consent.</li>
                    <li>To comply with legal obligations.</li>
                    <li>With service providers who assist us in delivering our Services.</li>
                    <li>In connection with a merger, acquisition, or sale of all or part of our company.</li>
                </ul>

                <h2 className={styles.sectionHeader}>6. Your Choices</h2>
                <p className={styles.paragraph}>
                    You have the following rights regarding your personal information:
                </p>
                <ul className={styles.paragraph}>
                    <li>Access: You may request access to the personal information we hold about you.</li>
                    <li>Correction: You can correct inaccuracies in your personal information.</li>
                    <li>Deletion: You may request the deletion of your personal information.</li>
                    <li>Opt-Out: You can opt-out of receiving promotional communications from us.</li>
                </ul>

                <h2 className={styles.sectionHeader}>7. Cookies</h2>
                <p className={styles.paragraph}>
                    We do not use cookies on our website.
                </p>

                <h2 className={styles.sectionHeader}>8. Changes to this Privacy Policy</h2>
                <p className={styles.paragraph}>
                    We reserve the right to update, modify, or revise this Privacy Policy at any time. We will notify you of any changes by posting the updated policy on our website.
                </p>

                <h2 className={styles.sectionHeader}>9. Contact Information</h2>
                <p className={styles.paragraph}>
                    If you have questions or concerns about this Privacy Policy or our data practices, please contact us.
                </p>
            </div>
       </div>
   </div>
  );
}

export default PrivacyMiddle;