import React, { useState } from "react";
import styles from "./SignUp.module.css";
import TopBarHome from "../../Components/TopBarHome/TopBarHome";
import Footer from "../../Components/Footer/Footer";
import { API_BASE_URL} from "../../Config/Config";
import { getToken } from "../../Helpers/Helpers";
import { message } from "antd";
import axios from 'axios';

function SignUp() {
  const [email, setEmail] = useState("");
  const [storeName, setStoreName] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const onPressReachOut = () => {

    axios.post( `${API_BASE_URL}api/useressential/new-lead`,{
        'email': email,
        'storeName': storeName,
        'name': name,
        'phoneNumber': phoneNumber
       },
       {headers: {
         'Authorization': `Bearer ${getToken()}`,
         // 'Content-Type': 'application/json' 
       }})
       .then(response => {
           message.success("Reached Out");
       })
       .catch(error => {
         message.error("Something went wrong");
       })
  };

  return (
    <div className={styles.App}>
      <TopBarHome />
      <div className={styles.MiddleMyMarket}>
        <div className={styles.MMMain}>
          <div className={styles.MMLogoContainer}>
            <span className={styles.MMLogoContainerText}>Reach Out</span>
          </div>
          <form className={styles.FormContainer}>
            <div className={styles.MMInputContainer}>
              <input
                value={email}
                placeholder="Email"
                onChange={ev => setEmail(ev.target.value)}
                className={styles.MMInput}
              />
            </div>
            <div className={styles.MMInputContainer}>
              <input
                value={phoneNumber}
                placeholder="Phone Number"
                onChange={ev => setPhoneNumber(ev.target.value)}
                className={styles.MMInput}
              />
            </div>
            <div className={styles.MMInputContainer}>
              <input
                value={storeName}
                placeholder="Store Name"
                onChange={ev => setStoreName(ev.target.value)}
                type="text"
                className={styles.MMInput}
              />
            </div>
            <div className={styles.MMInputContainer}>
              <input
                value={name}
                placeholder="Name"
                onChange={ev => setName(ev.target.value)}
                type="text"
                className={styles.MMInput}
              />
            </div>
            <div className={styles.ButtonContainer}>
              <button
                type="button"
                className={styles.MMButton}
                onClick={onPressReachOut}
              >
                <span className={styles.MMButtonText}>Reach Out</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
