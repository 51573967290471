import React from "react";
import styles from "./TopBar.module.css";
import { Link } from "react-router-dom";

function TopBar() {
  return (
   <div className={styles.Main}>

   </div>
  );
}

export default TopBar;