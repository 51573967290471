// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomePageOG_Container__hVtz6 {
    display: flex;
    background: #FF6EC7;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}
.HomePageOG_BottomBar__gMvtk {
    display: flex;
    background: #FF6EC7;
    /* background: black; */
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: 80px;
    flex-direction: row;
}
.HomePageOG_TextBottomBar__20fb2 {
    font-weight: 700;
    font-size: 16px;
    font-family: "Inter";
    line-height: 24px;
    color:white;
    text-decoration: none;
}
.HomePageOG_TextBottomBar1__5ntBT {
    font-weight: 700;
    font-size: 16px;
    font-family: "Inter";
    line-height: 24px;
    color:white;
    text-decoration: none;
    margin-left: 30px;
}
.HomePageOG_Main__EOghW {
    display: flex;
    background: #FF6EC7;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 160px);
}
.HomePageOG_Image__fYmD5 {
    resize: both;
    width: 400px;
}

`, "",{"version":3,"sources":["webpack://./src/Components/HomePageOG/HomePageOG.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;IACZ,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;IACZ,YAAY;IACZ,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,iBAAiB;IACjB,WAAW;IACX,qBAAqB;AACzB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,iBAAiB;IACjB,WAAW;IACX,qBAAqB;IACrB,iBAAiB;AACrB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,YAAY;IACZ,2BAA2B;AAC/B;AACA;IACI,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".Container {\n    display: flex;\n    background: #FF6EC7;\n    flex-wrap: nowrap;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    width: 100vw;\n    height: 100vh;\n}\n.BottomBar {\n    display: flex;\n    background: #FF6EC7;\n    /* background: black; */\n    flex-wrap: nowrap;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    width: 100vw;\n    height: 80px;\n    flex-direction: row;\n}\n.TextBottomBar {\n    font-weight: 700;\n    font-size: 16px;\n    font-family: \"Inter\";\n    line-height: 24px;\n    color:white;\n    text-decoration: none;\n}\n.TextBottomBar1 {\n    font-weight: 700;\n    font-size: 16px;\n    font-family: \"Inter\";\n    line-height: 24px;\n    color:white;\n    text-decoration: none;\n    margin-left: 30px;\n}\n.Main {\n    display: flex;\n    background: #FF6EC7;\n    flex-wrap: nowrap;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    width: 100vw;\n    height: calc(100vh - 160px);\n}\n.Image {\n    resize: both;\n    width: 400px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `HomePageOG_Container__hVtz6`,
	"BottomBar": `HomePageOG_BottomBar__gMvtk`,
	"TextBottomBar": `HomePageOG_TextBottomBar__20fb2`,
	"TextBottomBar1": `HomePageOG_TextBottomBar1__5ntBT`,
	"Main": `HomePageOG_Main__EOghW`,
	"Image": `HomePageOG_Image__fYmD5`
};
export default ___CSS_LOADER_EXPORT___;
