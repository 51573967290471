import React, {useState, useEffect, useRef} from 'react';
import styles from './OddsModal.module.css';
import { message } from "antd";
import { Spinner } from "react-activity";
import { API_BASE_URL} from "../../Config/Config";
import { getToken } from "../../Helpers/Helpers";
import axios from 'axios';
import ImageLogout from "../../Images/Logout.png";
import Coupon from "../../Images/Coupon.png";
import Pencil from "../../Images/PencilEmoji.png";
import Burger from "../../Images/HamburgerEmoji.png";
import Pizza from "../../Images/PizzaEmoji.png";
import Dessert from "../../Images/DessertEmoji.png";
import Sushi from "../../Images/SushiEmoji.png";
import Salad from "../../Images/SaladEmoji.png";
import Pasta from "../../Images/PastaEmoji.png";
import Egg from "../../Images/EggEmoji.png";
import Bagel from "../../Images/BagelEmoji.png";
import Avocado from "../../Images/AvocadoEmoji.png";
import Fries from "../../Images/FriesEmoji.png";
import Cake from "../../Images/CakeEmoji.png";
import Taco from "../../Images/TacoEmoji.png";
import Burrito from "../../Images/BurritoEmoji.png";
import Ramen from "../../Images/RamenEmoji.png";
import Pancakes from "../../Images/PancakesEmoji.png";
import Sandwich from "../../Images/SandwichEmoji.png";
import Croissant from "../../Images/CroissantEmoji.png";
import Coffee from "../../Images/CoffeeEmoji.png";
import BubbleTea from "../../Images/BubbleTeaEmoji.png";
import Donut from "../../Images/DonutEmoji.png";
import Beer from "../../Images/BeerEmoji.png";
import Wine from "../../Images/WineEmoji.png";
import TakeOut from "../../Images/TakeOutEmoji.png";
import Greece from "../../Images/GreeceEmoji.png";
import Indonesia from "../../Images/IndonesiaEmoji.png";
import Malaysia from "../../Images/MalaysiaEmoji.png";
import Vietnam from "../../Images/VietnamEmoji.png";
import Nepal from "../../Images/NepalEmoji.png";
import India from "../../Images/IndiaEmoji.png";
import Italy from "../../Images/ItalyEmoji.png";
import { useNavigate, useParams } from 'react-router-dom';

function OddsModal({ onClose, coupons, restaurantId }) {
const [isSaveButtonDisabled, setSaveButtonDisabeled] = useState(true);
const [totalOdds, setTotalOdds] = useState(0);
const [newCoupons, setNewCoupons] = useState([...coupons]);
const [focusedIndex, setFocusedIndex] = useState(null);
const textAreaRefs = useRef([]);
const { id } = useParams();
const navigate = useNavigate();

const categoryImages = {
    'Burger': Burger,
    'Pizza': Pizza,
    'Dessert': Dessert,
    'Sushi': Sushi,
    'Salad': Salad,
    'Pasta': Pasta,
    'Egg': Egg,
    'Bagel': Bagel,
    'Avocado': Avocado,
    'Fries': Fries,
    'Cake': Cake,
    'Taco': Taco,
    'Burrito': Burrito,
    'Ramen': Ramen,
    'Pancakes': Pancakes,
    'Sandwich': Sandwich,
    'Croissant': Croissant,
    'Coffee': Coffee,
    'BubbleTea': BubbleTea,
    'Donut': Donut,
    'Beer': Beer,
    'Wine': Wine,
    'TakeOut': TakeOut,
    'Greece': Greece,
    'Indonesia': Indonesia,
    'Malaysia': Malaysia,
    'Vietnam': Vietnam,
    'Nepal': Nepal,
    'India': India,
    'Italy': Italy,
};

useEffect(() => {
    const sum = newCoupons.reduce((acc, curr) => acc + parseInt(curr.couponOdds), 0);
    setTotalOdds(sum);
    if (sum === 1000){
        setSaveButtonDisabeled(false)
    }
    else{
        setSaveButtonDisabeled(true)
    }
}, [newCoupons]);

useEffect(() => {
    textAreaRefs.current[0]?.focus();
}, []);


const handleCreateCoupon = async() => {
    console.log('create coupon')
    console.log('odds', newCoupons)
    setSaveButtonDisabeled(true)
    axios.post( `${API_BASE_URL}api/activate-coupon`,{
        'coupons': newCoupons,
        'restaurantId': restaurantId
    },
    {headers: {
        'Authorization': `Bearer ${getToken()}`,
        // 'Content-Type': 'application/json' 
    }})
    .then(response => {
        setSaveButtonDisabeled(false)
        message.success("Success");
        onClose()
        navigate( `/MyMarketDashboard/${id}/${restaurantId}/Coupon`, { replace: true })
    })
    .catch(error => {
        message.error("ERROR, TRY AGAIN LATER");
        setSaveButtonDisabeled(false)
    })
};

const handleOddsChange = async (e, index) => {
    const { value } = e.target;
    const numericValue = parseInt(value.replace(/\D/g, '')); // Convert string to number
    const updatedCoupons = newCoupons.map((coupon, i) => {
        if (i === index) {
            return {
                ...coupon,
                couponOdds: isNaN(numericValue) ? 0 : numericValue,
            };
        }
        return coupon;
    });
    setNewCoupons(updatedCoupons);
};

const handleFocus = async (e, index) => {
    setFocusedIndex(index);
    e.target.select();
};

const handleBlur = async () => {
    setFocusedIndex(null);
};
    
const handleKeyDown = async (e, index) => {
    if (e.key === 'Enter') {
        const nextIndex = index + 1;
        if (nextIndex < textAreaRefs.current.length) {
            textAreaRefs.current[nextIndex].focus();
        }
    }
};

const handleDeleteRow = async(index) => {
    const updatedCoupons = [...newCoupons];
    const deletedCoupon = updatedCoupons.splice(index, 1)[0]; // Remove the coupon at the specified index
    const newTotalOdds = totalOdds - parseInt(deletedCoupon.couponOdds); // Subtract the deleted coupon's odds from the total
    setNewCoupons(updatedCoupons);
};

  return (
    <div className={styles.ModalOverlay}>
        <div className={styles.Modal}>
            <button className={styles.ModalClose} onClick={onClose}>
                <img src={ImageLogout} alt={"LogoutIma"} className={styles.ExitImage} />
            </button>
            <div
                className={styles.TitleTopModal}
            >
                    <span
                        className={styles.TitleTopModalText}
                    >
                        Activate Coupons
                    </span>
            </div>
            <div
                className={styles.TopModal}
            >
                <span
                    className={styles.TopModalText}
                >
                    Please ensure the total odds add up to <span className={styles.TopModalTextBold}>1000</span>.
                </span>
                <span
                    className={styles.TopModalText}
                >
                    You can set unused coupons to 0 or click 'Don't Include'.
                </span>
                <span
                    className={styles.TopModalText}
                >
                    Remember to click <span className={styles.TopModalTextBold}>'Activate Coupons'</span> when you're done!
                </span>
            </div>

            <div
                className={styles.MiddleModal}
            >
                {Array.isArray(newCoupons) && newCoupons.map((item, index) => (
                    <div
                        className={styles.OverviewContainer}
                        key={index}
                    >
                        <div
                            className={styles.OverviewContainer1}
                        >
                            <div
                                className={styles.CouponOverviewName}
                                style={
                                    item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                    item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                    item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                    item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                    item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                                    {}
                                }
                            >
                                <img src={Coupon} alt={"Coupon"} className={styles.CouponImage2} />
                                <span
                                    className={styles.CouponOverviewText}
                                >
                                {item.couponType === 'free' ? `FREE ${item.discountItem}` :
                                    item.couponType === 'bogo' && item.isAllItems === false ? `1+1 ${item.discountItem}` :
                                    item.couponType === 'bogo' && item.isAllItems === true ? `1+1 All Items` :
                                    item.couponType === 'percentageDiscount' ? `- ${item.discountAmount/100}%` :
                                    item.couponType === 'fixedDiscount' ? `- ${item.discountAmount/100} AUD` :
                                    item.couponType === 'fixedPrice' ? `${item.discountItem} @ ${item.couponFixedPrice/100} AUD` :
                                    
                                    ''
                                }
                                </span>
                            </div>
                        </div>
                        <div
                            className={styles.OverviewContainer2}
                        >
                            <div
                                className={styles.CardButton2Circle}
                            >
                                <img src={categoryImages[item.emoji]} alt={item.emoji} className={styles.EmojiImage} />
                            </div>
                        </div>
                        <div
                            className={styles.OverviewContainer3}
                        >
                            <div
                                className={`${styles.OverviewContainer3Button} ${focusedIndex === index ? styles.OverviewContainer3ButtonFocus : ''}`}
                                // onClick={handleOddsClick}
                            >
                                <textarea
                                    type="text"
                                    className={styles.TopInfoTitleContainerTextInput}
                                    style={{
                                        border: '2px solid transparent',
                                        outline: 'none',
                                        fontSize: '17px',
                                        fontWeight: '700',
                                        fontFamily: 'Rubik',
                                        resize: 'none',
                                        alignItems: 'center',
                                        justifyContent:'center',
                                        width: '50px',
                                        height: '18px',
                                        backgroundColor:'#fcfbf8',
                                        overflow: 'hidden',
                                        textAlign: 'right',
                                    }}
                                    ref={(el) => textAreaRefs.current[index] = el}
                                    value={item.couponOdds}
                                    onChange={(e) => handleOddsChange(e, index)}
                                    pattern="[0-9]*"
                                    maxLength={4}
                                    onFocus={(e) => handleFocus(e, index)} // <-- Update this line
                                    onBlur={handleBlur} 
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                />
                                  <span
                                        className={styles.TopInfoTitleContainerText3Input}
                                    >/1000
                                    </span>
                                
                            </div>
                        </div>
                        <div
                            className={styles.OverviewContainer4}
                        >
                            <button
                                className={styles.OverviewContainer4Button}
                                onClick={() => handleDeleteRow(index)} 
                            >
                                <span
                                    className={styles.TopInfoTitleContainerText4}
                                >
                                    Don't Include
                                </span>
                            </button>
                        </div>
                    </div>
                ))}

            </div>
            <div
                className={styles.TotalModal}
            >
                <div
                    className={styles.OverviewContainerBottom1}
                >

                </div>
                <div
                    className={styles.OverviewContainerBottom2}
                >
                    <span
                        className={styles.TotalText}
                    >
                        Total:
                    </span>
                </div>
                <div
                    className={styles.OverviewContainerBottom3}
                >
                    <span
                        className={styles.TopInfoTitleContainerText3}
                    >
                            <span 
                                className={styles.TopInfoTitleContainerText3Bold}
                                style={
                                    totalOdds === 1000 ? {color: 'green', cursor: 'auto'} :
                                    {color: 'red', cursor: 'auto'}
                                }
                            >{totalOdds}</span>/1000
                    </span>
                </div>
                <div
                    className={styles.OverviewContainerBottom4}
                >
                    <button
                        type='button' 
                        className={isSaveButtonDisabled ? styles.SaveContainerDisabled : styles.SaveContainer}
                        onClick={handleCreateCoupon} 
                        disabled={isSaveButtonDisabled}
                        style={{
                            border: isSaveButtonDisabled ? null : 'none', 
                            outline: isSaveButtonDisabled ? null : 'none',
                            cursor: isSaveButtonDisabled ? 'inherit' : 'pointer',
                        }}
                    >
                        <span 
                            className={isSaveButtonDisabled? styles.SaveContainerTextDisabled : styles.SaveContainerText}
                        >
                                 Activate Coupons
                        </span>
                    </button>
                </div>
            </div>
            <div
                className={styles.BottomModal}
            >
            </div>
        </div>
    </div>
  );
}

export default OddsModal;