import React, {useState} from 'react';
import styles from './SidebarStore.module.css';
import { useOutletContext } from "react-router-dom";
import { Spinner } from "react-activity";
import { message } from "antd";
import { API_BASE_URL} from "../../Config/Config";
import { getToken } from "../../Helpers/Helpers";
import axios from 'axios';
import ImageUploading from 'react-images-uploading';

function SidebarStore() {
const { restaurantData } = useOutletContext(); 
const [isLoading, setIsLoading] = useState(false);
const [isEditTimes, setEditTimes] = useState(false);
const [hasTimesChanged, setHasTimesChanged] = useState(false);
const [openingHours, setOpeningHours] = useState(restaurantData.restaurantPublic.restaurantTimes.join('\n'));
const [hours, setHours] = useState(restaurantData.restaurantPublic.restaurantTimes);
const restaurantName = restaurantData.restaurantPublic.restaurantName;
const subject = `logo ${restaurantName}`;
const body = "DO NOT DELETE!: Add logo as an attachement to this email";
const mailtoLink = `mailto:nicolas@sustainabite.com.au?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
const handleTextareaChange = (e) => {
    setOpeningHours(e.target.value);
    setHasTimesChanged(e.target.value !== restaurantData.restaurantPublic.restaurantTimes.join('\n'));
};

const handleSaveTimes = async () => {
    setIsLoading(true);
    setHasTimesChanged(false);
    setEditTimes(false)
    const timesArray = openingHours.split('\n');

    axios.post( `${API_BASE_URL}api/update-time-info`,{
        'restaurantId': restaurantData.restaurantPublic.id,
        'restaurantTimes': timesArray
       },
       {headers: {
         'Authorization': `Bearer ${getToken()}`,
         // 'Content-Type': 'application/json' 
       }})
       .then(response => {
           console.log('response', response)
           setIsLoading(false)
           setHours(timesArray)
           message.success("Opening Hours saved");
       })
       .catch(error => {
         message.error("Something went wrong");
         setIsLoading(false)
       })
};


const handleEdit = async() => {
    setEditTimes(true)
};

return (
    <div
        className={styles.Main}
    >
        <div
            className={styles.TitleContainer}
        >
            <span
                className={styles.TitleContainerText}
            >
                Store Information
            </span>
        </div>


        
        <div
            className={styles.TopInfoContainer}
        >
            <div
                className={styles.TopInfoTitleContainer}
            >
                <span
                    className={styles.TopInfoTitleContainerText}
                >
                    Logo
                </span>
            </div>
            {restaurantData.restaurantPublic.logoRestaurant === null?
            <>
                <div className={styles.UploadWrapper}>
                    <a
                        className={styles.UploadButton}
                        href={mailtoLink} target="_blank" rel="noopener noreferrer"
                    >
                        Upload Logo
                    </a>
                </div>
            </>
            :
            <img src={restaurantData.restaurantPublic.logoRestaurant.url} alt="" width="150" marginLeft="20" />
            }
        </div>


        <div
            className={styles.TopInfoContainer}
        >
            <div
                className={styles.TopInfoTitleContainer}
            >
                <span
                    className={styles.TopInfoTitleContainerText}
                >
                    Store information
                </span>
                    <button 
                        type='button' 
                        className={!hasTimesChanged ? styles.SaveButton : styles.SaveButtonDisabled}
                        // onClick={handleSavePayoutDetails} 
                        onClick={handleSaveTimes} 
                        disabled={!hasTimesChanged || isLoading }
                        style={{
                        border: null, 
                        outline: 'none',
                        cursor: !hasTimesChanged ? 'inherit' : 'pointer',
                        }}
                    >
                        {isLoading?
                            <Spinner 
                            color='gray'
                            size='8'
                            />
                        :
                            <span className={!hasTimesChanged ? styles.SaveButtonText : styles.SaveButtonTextDisabled}>
                                Save changes
                            </span>
                        }
                    </button>
            </div>
            <span
                className={styles.MiddleInfoHeadText}
            >
                Store Name
            </span>
            <span
                className={styles.MiddleInfoText}
            >
                {restaurantData.restaurantPublic.restaurantName}
            </span>
            <span
                className={styles.MiddleInfoHeadText}
            >
                Address
            </span>
            <span
                className={styles.MiddleInfoText}
            >
                {restaurantData.restaurantPublic.restaurantAddress}
            </span>
            <div
                className={styles.MiddleInfo}
            >
                <span
                    className={styles.MiddleInfoHeadText2}
                >
                    Opening Times
                </span>
                {isEditTimes?
                null
                :
                <span
                    className={styles.MiddleInfoButton}
                    onClick={handleEdit}
                >
                    Edit times
                </span>
                }
            </div>
            {isEditTimes?
            <>
            <span
                className={styles.MiddleInfoHeadTextSub}
            >
                The dates should have the following scheme:
            </span>
            <span
                className={styles.MiddleInfoHeadTextSub2}
            >
                Day: xx:xx am/pm - xx:xx am/pm
            </span>
            <span
                className={styles.MiddleInfoHeadTextSub3}
            >
                Ex: Friday: 10:00 am - 7:00 pm
            </span>
            <span
                className={styles.MiddleInfoHeadTextSub}
            >
                If you close over a certain period of time use the following scheme:
            </span>
            <span
                className={styles.MiddleInfoHeadTextSub3}
            >
                Ex: Friday: 10:00 am - 01:00 pm, 5:00 pm - 9:00 pm
            </span>
            <span
                className={styles.MiddleInfoHeadTextSub}
            >
                If you are closed during the day use the following scheme:
            </span>
            <span
                className={styles.MiddleInfoHeadTextSub3}
            >
                Ex: Friday: Closed
            </span>
            <textarea
                value={openingHours}
                onChange={handleTextareaChange}
                className={styles.TextAreaOpeningHours}
                style={{
                    border: '2px solid #FF6EC7',
                    outline: 'none',
                    resize: 'none',
                    backgroundColor:'#fcfbf8',
                    background:'white',
                    padding: '10px',
                    marginLeft: '20px',
                    fontSize: '14px',
                    fontWeight: '400' ,
                    fontFamily: "Rubik",
                    color: 'black',
                    height: '150px',
                    width:'350px',
                    borderRadius: '10px',
                    marginTop:'10px'
                }}
            />
             <button 
                type='button' 
                className={!hasTimesChanged ? styles.SaveButton2 : styles.SaveButtonDisabled2}
                // onClick={handleSavePayoutDetails} 
                onClick={handleSaveTimes} 
                disabled={!hasTimesChanged || isLoading }
                style={{
                border: null, 
                outline: 'none',
                cursor: !hasTimesChanged ? 'inherit' : 'pointer',
                }}
            >
                {isLoading?
                    <Spinner 
                    color='gray'
                    size='8'
                    />
                :
                    <span className={!hasTimesChanged ? styles.SaveButtonText : styles.SaveButtonTextDisabled}>
                        Save changes
                    </span>
                }
            </button>
            </>
            :
            <>
            <span
                className={styles.MiddleInfoText}
            >
                 {hours.map((time, index) => (
                    <div key={index}>
                        {time}
                    </div>
                ))}
            </span>
            </>
            }
        </div>
    </div>
);
}

export default SidebarStore;