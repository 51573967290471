import React from "react";
import styles from "./TermsMiddle.module.css";
import Image from "../../Images/OpenBookEmoji.png";
import { Link } from "react-router-dom";


function TermsMiddle() {
  return (
   <div 
        className={styles.Main}
    >
       <div
            className={styles.TermsContainer}
       >
            <div
                className={styles.TitleContainer}
            >
                <img src={Image} alt="BookEmoji" className={styles.Image} />
                <text
                    className={styles.TitleText}
                >
                    Terms and Conditions
                </text>
            </div>
            <div
                className={styles.InfoContainer}
            >
                <h2 className={styles.sectionHeader}>1. Introduction</h2>
                <p className={styles.paragraph}>
                    This Terms and Conditions ("Agreement") govern your use of the Sustainabite website ("Website") and the services provided by Sustainabite ("Services"). By accessing or using our Website and Services, you agree to comply with and be bound by this Agreement. If you do not agree to these terms, please do not use our Website or Services.
                </p>
                <h2 className={styles.sectionHeader}>2. Company Information</h2>
                <p className={styles.paragraph}>
                    Sustainabite
                </p>
                <p className={styles.paragraph}>
                    ABN: 60795762865
                </p>
                <p className={styles.paragraph}>
                    0480 543 610
                </p>

                <h2 className={styles.sectionHeader}>3. Description of Services</h2>
                <p className={styles.paragraph}>
                    Sustainabite provides a food waste app designed to help users reduce food waste by connecting them with businesses offering surplus food products at discounted prices.
                </p>

                <h2 className={styles.sectionHeader}>4. User Responsibilities</h2>
                <p className={styles.paragraph}>
                    - You must be at least 18 years old to use our Services.
                </p>
                <p className={styles.paragraph}>
                    - You agree to provide accurate and truthful information when using our Services.
                </p>
                <p className={styles.paragraph}>
                    - You are responsible for maintaining the confidentiality of your account credentials and are liable for any activity conducted through your account.
                </p>

                <h2 className={styles.sectionHeader}>5. Privacy Policy</h2>
                <p className={styles.paragraph}>
                    Please review our <Link to="/Privacy" className={styles.Link}>Privacy Policy</Link> for information on how we collect, use, and protect your personal information.
                </p>

                <h2 className={styles.sectionHeader}>6. Intellectual Property</h2>
                <p className={styles.paragraph}>
                    All content, trademarks, logos, and intellectual property on the Website are the property of Sustainabite or its licensors and may not be used without prior written consent.
                </p>

                <h2 className={styles.sectionHeader}>7. Limitation of Liability</h2>
                <p className={styles.paragraph}>
                    Sustainabite is not responsible for any indirect, incidental, special, consequential, or punitive damages arising from the use or inability to use our Services.
                </p>

                <h2 className={styles.sectionHeader}>8. Termination</h2>
                <p className={styles.paragraph}>
                    Sustainabite reserves the right to terminate or suspend your account and access to the Services at our discretion, with or without cause, and with or without notice.
                </p>

                <h2 className={styles.sectionHeader}>9. Governing Law</h2>
                <p className={styles.paragraph}>
                    This Agreement is governed by the laws of Australia. Any disputes arising from this Agreement will be subject to the exclusive jurisdiction of the courts of Australia.
                </p>

                <h2 className={styles.sectionHeader}>10. Changes to Terms and Conditions</h2>
                <p className={styles.paragraph}>
                    Sustainabite reserves the right to update, modify, or revise these Terms and Conditions at any time. Your continued use of our Services after any changes indicate your acceptance of the revised terms.
                </p>

                <h2 className={styles.sectionHeader}>11. Contact Information</h2>
                <p className={styles.paragraph}>
                    If you have any questions or concerns about these Terms and Conditions, please contact us.
                </p>
            </div>
       </div>
   </div>
  );
}

export default TermsMiddle;