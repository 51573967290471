import React, {useEffect, useState} from "react";
import styles from "./MyMarketStorePicker.module.css";
import MyMarketDashboardSearchStore from "../../Components/MyMarketDashboardSearchStore/MyMarketDashboardSearchStore";
import Loading from "../../Components/Loading/Loading";
import StorePickerTop from "../../Components/StorePicker/StorePickerTop";
import { useParams, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../Context/AuthContext";


function MyMarketStorePicker() {
const [storeInfo, setStoreInfo] = useState(null)
const { id } = useParams();
const { user } = useAuthContext();
const navigate = useNavigate();


function handleNext() {
  navigate( `/MyMarketTag/${id}`, { state: storeInfo }) 
}

function receiveStoreInfo(info) {
  setStoreInfo(info);
}

useEffect(() => {
  console.log('user1', user)
  if(user === undefined){
    navigate( "/MyMarket", { replace: true })
  }
  else{
    if (user.id !== Number(id)) {
      navigate( "/MyMarket", { replace: true })
    } 
  }
}, [user]);

if (!user) {
  // Handle the case when user is not authorized to view this dashboard
  return (
    <Loading/>
  );
}
if (user === undefined) {
  // Handle the case where 'user' is explicitly undefined
  return (
    <Loading/>
  );
}

  return (
    <div className={styles.App}>
      <StorePickerTop/>
      <MyMarketDashboardSearchStore
        onClickNext={handleNext}
        onStoreInfoChange={receiveStoreInfo}
      />
    </div>
  );
}

export default MyMarketStorePicker;