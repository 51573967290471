import React from "react";
import styles from "./ContactPrivacyMiddle.module.css";


function ContactPrivacyMiddle() {
  return (
   <div 
        className={styles.Main}
    >
       <div
            className={styles.TermsContainer}
       >
            <div
                className={styles.TitleContainer}
            >
                <text
                    className={styles.TitleText}
                >
                    Delete Your Data
                </text>
            </div>
            <div
                className={styles.InfoContainer}
            >
                <h2 className={styles.sectionHeader}>How to delete your data:</h2>
                <p className={styles.paragraph}>
                    Send an email to <a className={styles.TextDiv1Options} href="mailto:nicolas@sustainabite.com.au" target="_blank" rel="noopener noreferrer">
                    nicolas@sustainabite.com.au 
                </a> with either your email or phonenumber attached to your account with the subject "DELETE MY DATA". 
                </p>
                <p className={styles.paragraph}>
                Upon receiving your request, we will promptly proceed with the removal of all data associated with your account. Please note that by submitting a data removal request, all of your personal information, including but not limited to Username, phonenumber, email, buying history..., will be permanently deleted from our records. Any additional data retention periods, if applicable, will also be clearly communicated to you upon request. We strive to ensure transparency and compliance with data privacy regulations, and we are committed to honoring your data rights. If you have any questions or concerns regarding the removal of your data, please do not hesitate to contact us.
                </p>
                <p className={styles.paragraph}>
                    This action can not be reversed
                </p>
            </div>
       </div>
   </div>
  );
}

export default ContactPrivacyMiddle;