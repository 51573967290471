import React, {useState, useEffect} from "react";
import styles from "./MyMarket.module.css";
import TopBar from "../../Components/TopBar/TopBar";
import Footer from "../../Components/Footer/Footer";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { API_BASE_URL } from '../../Config/Config';
import { Spinner } from "react-activity";
import { useAuthContext } from "../../Context/AuthContext";
import { setToken } from "../../Helpers/Helpers";
import "react-activity/dist/library.css";


function MyMarket() {
const [email, setEmail] = useState("")
const [password, setPassword] = useState("")
const [isButtonDisabled, setIsButtonDisabled] = useState(true);
const [isEmailIncorrect, setIsEmailIncorrect] = useState(false);
const [isLoading, setIsLoading] = useState(false);

const navigate = useNavigate();
  
const { setUser } = useAuthContext();

useEffect(() => {
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isPasswordValid = password.length >= 1;
  setIsButtonDisabled(!(isEmailValid && isPasswordValid));
}, [email, password]);

const handleLogin = async() => {
    setIsLoading(true)
    setIsButtonDisabled(true)
    axios
    .post(`${API_BASE_URL}api/auth/local`, {
      "identifier": email.toLowerCase(),
      "password": password,
    })
  .then(response => {
    if (response.data.user.type === "restaurant")
    {
      setToken(response.data.jwt);
      setUser(response.data.user);
      const userId = response.data.user.id;
      axios.post( `${API_BASE_URL}api/check-picked`,{
        'userId': userId,
       },
       {headers: {
         'Authorization': `Bearer ${response.data.jwt}`,
         // 'Content-Type': 'application/json' 
       }})
       .then(res => {
         console.log('check picked', res.data)
          if (res.data.exists === false){ 

            navigate( `/MyMarketStorePicker/${userId}`, { replace: true })
          }
          else {
            navigate( `/MyMarketDashboard/${userId}/${res.data.data}/Dashboard`, { replace: true })
          }
       })
       .catch(error => {
         console.error('no',error);
       })
    }

    else {
      setIsEmailIncorrect(true)
      setPassword('')
      setIsLoading(false)
    }
  })
  .catch(error => {
    setIsLoading(false)
    setIsEmailIncorrect(true)
    setPassword('')
    console.log('resp', error)
  });
};

const handleKeyPress = (e) => {
  if (e.key === 'Enter' && isButtonDisabled === false) {
    // Trigger the click event of the login button when Enter is pressed.
    handleLogin();
  }
};

  return (
    <div className={styles.App}>
      <TopBar/>
        <div
          className={styles.MiddleMyMarket}
        >
          <div
            className={styles.MMMain}
          >
            <div
              className={styles.MMLogoContainer}
            >
              <span 
                className={styles.MMLogoContainerText}
              >
                MyMarket
              </span>
            </div>
            <form>
            <div
              className={styles.MMEmailContainer}
            >
              <input
                value={email}
                placeholder="Email"
                onChange={ev => setEmail(ev.target.value)}
                className={styles.MMEmailContainerText}
                style={{
                  outline: 'none'
                }}
              />
            </div>
            <div
              className={styles.MMPasswordContainer}
            >
              <input
                value={password}
                placeholder="Password"
                onChange={ev => setPassword(ev.target.value)}
                type="password"
                className={styles.MMEmailContainerText} 
                onKeyPress={handleKeyPress}
                style={{
                  outline: 'none'
                }}
                />
                
            </div>
            <div
              className={styles.MMLoginContainer}
            >
                {isEmailIncorrect && (
                  <span 
                    className={styles.MMErrorContainerText}
                  >
                    Email/Password wrong
                  </span>
                )}
            </div>
            </form>
            <div
              className={styles.MMErrorContainer}
            >
                              <button 
                    type='button' 
                    className={isButtonDisabled ? styles.MMLoginButton : styles.MMLoginButtonDisabled}
                    onClick={handleLogin} 
                    disabled={isButtonDisabled || isLoading }
                    style={{
                    border: isButtonDisabled ? null : 'none', 
                    outline: isButtonDisabled ? null : 'none',
                    cursor: isButtonDisabled ? 'inherit' : 'pointer',
                    }}
                >
                    {isLoading?
                        <Spinner 
                        color='#FF6EC7'
                        />
                    :
                        <span className={isButtonDisabled ? styles.MMLoginButtonText : styles.MMLoginButtonTextDisabled}>
                            login
                        </span>
                    }
              </button>
            </div>
            <div
              className={styles.MMGitContainer}
            >
                <Link to="/MyMarketRegister" className={styles.Link}>
                    <span className={styles.TextDiv1Options}>
                        Create an account
                    </span>
                </Link>
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  );
}

export default MyMarket;