import React from 'react';
import styles from "./HomePageP2.module.css";

function HomePageP2() {

  return (
    <div className={styles.Main}>
        <div className={styles.Div1}>
          <div className={styles.Div1TextContainer}>
            <span className={styles.Div1TextBlue}>
                New! 
            </span>
            <span className={styles.Div1Text}>
                Get your first 90 days free
            </span>
          </div>
      </div>
      <div className={styles.Div2}>
        <span className={styles.Div2Text}>
          The next gen of salary packaging.
        </span>
      </div>
      <div className={styles.Div3}>
        <span className={styles.Div3Text}>
          Up to 80% cheaper, The best salary packaging software for NFP employers.
        </span>
      </div>
      <div className={styles.Div4}>
        <a 
            className={styles.DivContact}
            href="https://calendly.com/salary-pie" // Replace with your Calendly URL
            target="_blank" 
            rel="noopener noreferrer" 
        >
            <span className={styles.TextDivContact}>
                Talk to us
            </span>
        </a>
      </div>
    </div>
  );
};

export default HomePageP2;