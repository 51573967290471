import React, {useState} from 'react';
import styles from './BagModal.module.css';
import Todo from "../../Images/Todo.png";
import { message } from "antd";
import { Spinner } from "react-activity";
import { API_BASE_URL} from "../../Config/Config";
import { getToken } from "../../Helpers/Helpers";
import axios from 'axios';
import ImageLogout from "../../Images/Logout.png";
import ThumbUp from "../../Images/ThumbUpEmoji.png";
import ThumbDown from "../../Images/ThumbDownEmoji.png";
import Burger from "../../Images/HamburgerEmoji.png";
import Pizza from "../../Images/PizzaEmoji.png";
import Dessert from "../../Images/DessertEmoji.png";
import Sushi from "../../Images/SushiEmoji.png";
import Salad from "../../Images/SaladEmoji.png";
import Pasta from "../../Images/PastaEmoji.png";
import Egg from "../../Images/EggEmoji.png";
import Bagel from "../../Images/BagelEmoji.png";
import Avocado from "../../Images/AvocadoEmoji.png";
import Fries from "../../Images/FriesEmoji.png";
import Cake from "../../Images/CakeEmoji.png";
import Taco from "../../Images/TacoEmoji.png";
import Burrito from "../../Images/BurritoEmoji.png";
import Ramen from "../../Images/RamenEmoji.png";
import Pancakes from "../../Images/PancakesEmoji.png";
import Sandwich from "../../Images/SandwichEmoji.png";
import Croissant from "../../Images/CroissantEmoji.png";
import Coffee from "../../Images/CoffeeEmoji.png";
import BubbleTea from "../../Images/BubbleTeaEmoji.png";
import Donut from "../../Images/DonutEmoji.png";
import Beer from "../../Images/BeerEmoji.png";
import Wine from "../../Images/WineEmoji.png";
import TakeOut from "../../Images/TakeOutEmoji.png";
import Greece from "../../Images/GreeceEmoji.png";
import Indonesia from "../../Images/IndonesiaEmoji.png";
import Malaysia from "../../Images/MalaysiaEmoji.png";
import Vietnam from "../../Images/VietnamEmoji.png";
import Nepal from "../../Images/NepalEmoji.png";
import India from "../../Images/IndiaEmoji.png";
import Italy from "../../Images/ItalyEmoji.png";
import Coupon from "../../Images/Coupon.png";
import Waste from "../../Images/WasteBasketEmoji.png";
import ImageLogo from "../../Images/Sus-a-bite-White.png";
import { useNavigate, useParams } from 'react-router-dom';
import { faL } from '@fortawesome/free-solid-svg-icons';

function Modal({ onClose, restaurantId }) {
const [inputItemValue, setInputItemValue] = useState('');
const [selectedCategory, setSelectedCategory] = useState(null);
const [emoji, setEmoji] = useState('');
const [discountAmount, setDiscountAmount] = useState(0);
const [fixedPrice, setFixedPrice] = useState(0);
const [originalValue, setOriginalValue] = useState(0);
const [minimumAmount, setMinimumAmount] = useState(0);
const [terms, setTerms] = useState(['']); // Initialize with an empty term
const [showInputs, setShowInputs] = useState(false);
const [isAllItems, setAllItems] = useState(true);
const [isMinimumSpend, setMinimumSpend] = useState(false);
const [couponType, setCouponType] = useState('');
const [focusTerm, setFocusTerm] = useState(false);
const [isDineIn, setIsDineIn] = useState(true);
const [isTakeaway, setIsTakeaway] = useState(true);
const [page, setPage] = useState('card');
const [isButtonDisabled, setIsButtonDisabled] = useState(true);
const [isLoading, setIsLoading] = useState(false);
const [isDiscountPopupOpen, setDiscountPopupOpen] = useState(false);
const [isFixedPopupOpen, setFixedPopupOpen] = useState(false);
const [isMinimumPopupOpen, setMinimumPopupOpen] = useState(false);
const maxLength = 250
const { id } = useParams();
const navigate = useNavigate();

const categories = [
    { name: "Dessert", image: Dessert },
    { name: "Burger", image: Burger },
    { name: "Pizza", image: Pizza },
    { name: "Sushi", image: Sushi },
    { name: "Cake", image: Cake },
    { name: "Fries", image: Fries },
    { name: "Salad", image: Salad },
    { name: "Pasta", image: Pasta },
    { name: "Pancakes", image: Pancakes },
    { name: "Croissant", image: Croissant },
    { name: "Sandwich", image: Sandwich },
    { name: "Egg", image: Egg },
    { name: "Avocado", image: Avocado },
    { name: "Bagel", image: Bagel },
    { name: "Taco", image: Taco },
    { name: "Burrito", image: Burrito },
    { name: "Ramen", image: Ramen },
    { name: "Coffee", image: Coffee },
    { name: "BubbleTea", image: BubbleTea },
    { name: "Donut", image: Donut },
    { name: "Beer", image: Beer },
    { name: "Wine", image: Wine },
    { name: "TakeOut", image: TakeOut },
    { name: "Greece", image: Greece },
    { name: "Indonesia", image: Indonesia  },
    { name: "Malaysia", image: Malaysia },
    { name: "Vietnam", image: Vietnam },
    { name: "Nepal", image: Nepal  },
    { name: "India", image: India },
    { name: "Italy", image: Italy },

];

const handleDiscountPopupOpen = () => {
    setDiscountPopupOpen(true);
}

const handleFixedPopupOpen = () => {
    setFixedPopupOpen(true);
}

const handleMinimumSelection = async (percentage) => {
    // Update the state with the selected percentage
    // You can use this selected percentage for further processing
    setMinimumSpend(true)
    setMinimumAmount(percentage)
    setMinimumPopupOpen(false); // Close the popup after selection
}

const handleNoMinimumSpend = () => {
    // Update the state with the selected percentage
    // You can use this selected percentage for further processing
    setMinimumSpend(false)
    setMinimumAmount(0)
    setMinimumPopupOpen(false); // Close the popup after selection
}

const handleDiscountSelection = async(percentage) => {
    // Update the state with the selected percentage
    // You can use this selected percentage for further processing

    setDiscountAmount(percentage)
    setIsButtonDisabled(false)
    setDiscountPopupOpen(false) // Close the popup after selection
    console.log('testv', inputItemValue)
    console.log('testa', discountAmount)
    console.log('testi', isAllItems)
    if (percentage !== 0 && isAllItems === true){
        setIsButtonDisabled(false)
    }
    else if (isAllItems === false && inputItemValue.length > 2 && discountAmount !== 0){
        setIsButtonDisabled(false)
    }
    else (
        setIsButtonDisabled(true)
    )
}

const handleFixedDiscountSelection = (percentage) => {
    // Update the state with the selected percentage
    // You can use this selected percentage for further processing
    setDiscountAmount(percentage)
    setIsButtonDisabled(false)
    setFixedPopupOpen(false); // Close the popup after selection
}

const handleCategoryEmojiClick = (index, category) => {
    setSelectedCategory(index);
    setEmoji(category.name)
    setIsButtonDisabled(false);
};

const isCategorySelected = (index) => selectedCategory === index;


const addTerm = async() => {
    if (showInputs === false){
        setShowInputs(true);
        setFocusTerm(true)
    }
    else{
        setFocusTerm(false)
        setTerms([...terms, '']);
    }
};

const handleTermChange = (index, event) => {
    const newTerms = [...terms];
    newTerms[index] = event.target.value;
    setTerms(newTerms);
};

const removeTerm = (index) => {
    const newTerms = [...terms];
    newTerms.splice(index, 1);
    setTerms(newTerms);
};

const handleCategoryClick = (category) => {
    if (category === 'Dine In') {
        setIsDineIn(!isDineIn);
    } else if (category === 'Take Out') {
        setIsTakeaway(!isTakeaway);
    }
};

const handleCategoryItemClick = () => {
        setAllItems(!isAllItems);
};

const handleMinimumSpend = async () => {
    setMinimumPopupOpen(true)
};

const handleInputItemChange = async (event) => {
    const newNameValue = event.target.value;
    setInputItemValue(newNameValue);
    if (newNameValue.length > 2 && originalValue !== 0) {
        setIsButtonDisabled(false);
    } else {
        setIsButtonDisabled(true);
    }
};

const handleFreeOriginalChange = async (event) => {
    const newNameValue = event.target.value;
    setOriginalValue(newNameValue);
    if (newNameValue !== 0 && inputItemValue.length > 2) {
        setIsButtonDisabled(false);
    } else {
        setIsButtonDisabled(true);
    }
};

const handleInputFixedDiscountItemChange = async (event) => {
    const newNameValue = event.target.value;
    setInputItemValue(newNameValue);
    if (newNameValue.length > 2 && discountAmount !== 0) {
        setIsButtonDisabled(false);
    } else {
        setIsButtonDisabled(true);
    }
};
const handleInputPercentageChange = async (event) => {
    const newNameValue = event.target.value;
    setInputItemValue(newNameValue);
    if (newNameValue.length > 2 && discountAmount !== 0) {
        setIsButtonDisabled(false);
    } else {
        setIsButtonDisabled(true);
    }
};

const handleInputItemFixedChange = async (event) => {
    const newNameValue = event.target.value;
    setInputItemValue(newNameValue);
    if (newNameValue.length > 2 && fixedPrice !== 0 && originalValue !== 0) {
        setIsButtonDisabled(false);
    } else {
        setIsButtonDisabled(true);
    }
};


const handleFixedPriceChange = async (event) => {
    const newNameValue = event.target.value;
    setFixedPrice(newNameValue);
    if (newNameValue !== 0 && originalValue !== 0 && inputItemValue.length > 2) {
        setIsButtonDisabled(false);
    } else {
        setIsButtonDisabled(true);
    }
};


const handleOriginalValueChange = async (event) => {
    const newNameValue = event.target.value;
    setOriginalValue(newNameValue);
    if (newNameValue !== 0 && fixedPrice !== 0 && inputItemValue.length > 2) {
        setIsButtonDisabled(false);
    } else {
        setIsButtonDisabled(true);
    }
};

const handleButtonFree = () => {
    setCouponType('free')
    setPage('free')
};

const handlePreviousFree = () => {
    setCouponType('')
    setInputItemValue('')
    setIsDineIn(true)
    setIsTakeaway(true)
    setIsButtonDisabled(true)
    setPage('card')
};

const handleNextFree = () => {
    setPage('terms')
};

const handleButtonPercentageDiscount = () => {
    setCouponType('percentageDiscount')
    setPage('percentageDiscount')
};

const handlePreviousPercentageDiscount = () => {
    setCouponType('')
    setInputItemValue('')
    setIsDineIn(true)
    setIsTakeaway(true)
    setAllItems(true)
    setIsButtonDisabled(true)
    setDiscountPopupOpen(false)
    setMinimumPopupOpen(false)
    setMinimumSpend(false)
    setMinimumAmount(0)
    setDiscountAmount(0)
    setPage('card')
};

const handleNextPercentageDiscount = () => {
    setDiscountPopupOpen(false)
    setMinimumPopupOpen(false)
    setPage('terms')
};


const handleButtonFixedPrice = () => {
    setCouponType('fixedPrice')
    setPage('fixedPrice')
};

const handlePreviousFixedPrice = () => {
    setCouponType('')
    setInputItemValue('')
    setIsDineIn(true)
    setIsTakeaway(true)
    setIsButtonDisabled(true)
    setOriginalValue(0)
    setFixedPrice(0)
    setPage('card')
};

const handleNextFixedPrice = () => {
    setPage('terms')
};

const handleButtonFixedDiscount = () => {
    setCouponType('fixedDiscount')
    setPage('fixedDiscount')
};

const handlePreviousFixedDiscount = () => {
    setCouponType('')
    setInputItemValue('')
    setIsDineIn(true)
    setIsTakeaway(true)
    setAllItems(true)
    setIsButtonDisabled(true)
    setFixedPopupOpen(false)
    setMinimumPopupOpen(false)
    setMinimumSpend(false)
    setMinimumAmount(0)
    setDiscountAmount(0)
    setPage('card')
};

const handleNextFixedDiscount = () => {
    setFixedPopupOpen(false)
    setMinimumPopupOpen(false)
    setPage('terms')
};

const handlePreviousTerms = () => {
    setTerms([])
    setShowInputs(false);
    setPage(couponType)
};

const handleNextTerms = () => {
    setIsButtonDisabled(true)
    setPage('emoji')
    console.log('terms', terms)
};

const handlePreviousEmoji = () => {
    setIsButtonDisabled(false)
    setSelectedCategory(null)
    setPage('terms')
};



const handleCreateCoupon = async() => {
    setIsLoading(true)
    setIsButtonDisabled(true)

    const discountAmountNum = Number(discountAmount) * 100;
    const fixedPriceNum = Number(fixedPrice);
    const originalValueNum = Number(originalValue);
    const minimumAmountNum = Number(minimumAmount) * 100;

    axios.post( `${API_BASE_URL}api/create-coupon`,{
        'item': inputItemValue,
        'discountAmount': discountAmountNum,
        'fixedPrice': fixedPriceNum,
        'originalValue': originalValueNum,
        'minimumAmount': minimumAmountNum,
        'terms': terms,
        'isAllItems': isAllItems,
        'isMinimumSpend': isMinimumSpend,
        'couponType': couponType,
        'isDineIn': isDineIn,
        'isTakeaway': isTakeaway,
        'emoji': emoji,
        'restaurantId': restaurantId
    },
    {headers: {
        'Authorization': `Bearer ${getToken()}`,
        // 'Content-Type': 'application/json' 
    }})
    .then(response => {
        setIsLoading(false)
        setIsButtonDisabled(false)
        message.success("Success");
        onClose()
        navigate( `/MyMarketDashboard/${id}/${restaurantId}/Coupon`, { replace: true })
    })
    .catch(error => {
        message.error("ERROR, TRY AGAIN LATER");
        console.log('error', error)
        setIsLoading(false)
        setIsButtonDisabled(false)
    })
};


  return (
    <div className={styles.ModalOverlay}>



        {/* DISCOUNTPERCENTAGE POPUP */}



        {isDiscountPopupOpen && (
            <div className={styles.DiscountPopup}>
                <div className={styles.DiscountOptions}>
                    <button onClick={() => handleDiscountSelection(20)}>20%</button>
                    <button onClick={() => handleDiscountSelection(25)}>25%</button>
                    <button onClick={() => handleDiscountSelection(30)}>30%</button>
                    <button onClick={() => handleDiscountSelection(35)}>35%</button>
                    <button onClick={() => handleDiscountSelection(40)}>40%</button>
                    <button onClick={() => handleDiscountSelection(45)}>45%</button>
                    <button onClick={() => handleDiscountSelection(50)}>50%</button>
                    <button onClick={() => handleDiscountSelection(55)}>55%</button>
                    <button onClick={() => handleDiscountSelection(60)}>60%</button>
                    <button onClick={() => handleDiscountSelection(65)}>65%</button>
                    <button onClick={() => handleDiscountSelection(70)}>70%</button>
                    {/* Add more buttons for other percentages */}
                </div>
                <button className={styles.CloseButton} onClick={() => setDiscountPopupOpen(false)}>
                    Close
                </button>
            </div>
        )}



        {/* MINIMUM SPEND POPUP */}




         {isMinimumPopupOpen && (
            <div className={styles.DiscountPopup}>
                <div className={styles.DiscountOptions}>
                    <button onClick={() => handleMinimumSelection(5)}>5 AUD</button>
                    <button onClick={() => handleMinimumSelection(8)}>8 AUD</button>
                    <button onClick={() => handleMinimumSelection(10)}>10 AUD</button>
                    <button onClick={() => handleMinimumSelection(15)}>15 AUD</button>
                    <button onClick={() => handleMinimumSelection(20)}>20 AUD</button>
                    <button onClick={() => handleMinimumSelection(25)}>25 AUD</button>
                    <button onClick={() => handleMinimumSelection(30)}>30 AUD</button>
                    <button onClick={() => handleMinimumSelection(35)}>35 AUD</button>
                    <button onClick={() => handleMinimumSelection(40)}>40 AUD</button>
                    <button onClick={() => handleMinimumSelection(50)}>50 AUD</button>
                    <button onClick={() => handleMinimumSelection(100)}>100 AUD</button>
                    {/* Add more buttons for other percentages */}
                </div>
                <button className={styles.CloseButton} onClick={handleNoMinimumSpend}>
                    No Minimum Spend
                </button>
            </div>
        )}



         {/* FIXEDDISCOUNT POPUP */}



         {isFixedPopupOpen && (
            <div className={styles.DiscountPopup}>
                <div className={styles.DiscountOptions}>
                    <button onClick={() => handleFixedDiscountSelection(2)}>2 AUD</button>
                    <button onClick={() => handleFixedDiscountSelection(3)}>3 AUD</button>
                    <button onClick={() => handleFixedDiscountSelection(4)}>4 AUD</button>
                    <button onClick={() => handleFixedDiscountSelection(5)}>5 AUD</button>
                    <button onClick={() => handleFixedDiscountSelection(6)}>6 AUD</button>
                    <button onClick={() => handleFixedDiscountSelection(8)}>8 AUD</button>
                    <button onClick={() => handleFixedDiscountSelection(10)}>10 AUD</button>
                    <button onClick={() => handleFixedDiscountSelection(15)}>15 AUD</button>
                    <button onClick={() => handleFixedDiscountSelection(20)}>20 AUD</button>
                    <button onClick={() => handleFixedDiscountSelection(25)}>25 AUD</button>
                    <button onClick={() => handleFixedDiscountSelection(30)}>30 AUD</button>
                    {/* Add more buttons for other percentages */}
                </div>
                <button className={styles.CloseButton} onClick={() => setFixedPopupOpen(false)}>
                    Close
                </button>
            </div>
        )}
        <div className={styles.Modal}>
            <button className={styles.ModalClose} onClick={onClose}>
                <img src={ImageLogout} alt={"LogoutIma"} className={styles.SidebarImage} />
            </button>




            {/* CARD CHOOSE PAGE */}





            {page === 'card' && (
                <div className={styles.ModalContent}>
                    <div
                        className={styles.TopContainer}
                    >
                        <div
                            className={styles.TitleContainer}
                        >
                            <span
                                className={styles.TitleContainerText}
                            >
                                Choose Coupon Type
                            </span>
                        </div>
                        <div
                            className={styles.MiddleContainer}
                        >
                            <div
                                className={styles.MiddleCardContainer}
                            >
                                <div
                                    className={styles.CardTitleContainer}
                                >
                                    <span
                                        className={styles.CardTitleContainerText}
                                    >
                                        1. Free Item
                                    </span>
                                </div>
                                <button
                                    className={styles.CardButton} 
                                    onClick={handleButtonFree}
                                >
                                    <div
                                        className={styles.CardButtonTop}
                                    >
                                        <img src={ImageLogo} alt={"LogoIma"} className={styles.LogoImage} />
                                    </div>
                                    <div
                                        className={styles.CardButtonMiddle}
                                    >
                                        <span
                                            className={styles.CardButtonMiddleText}
                                        >
                                            Free Item
                                        </span>
                                    </div>
                                    <div
                                        className={styles.CardButtonBottom}
                                    >
                                        <div
                                            className={styles.CardButtonValid}
                                        >
                                        <span
                                            className={styles.CardButtonValidText}
                                        >
                                            01/01/2025
                                        </span>
                                        </div>
                                        <div
                                            className={styles.CardButtonCircle}
                                        >
                                            <img src={Coupon} alt={"CouponIma"} className={styles.CouponImage} />
                                        </div>
                                    </div>
                                </button>
                            </div>

                            <div
                                className={styles.MiddleCardContainer}
                            >
                                <div
                                    className={styles.CardTitleContainer}
                                >
                                    <span
                                        className={styles.CardTitleContainerText}
                                    >
                                        2. Percentage Discount
                                    </span>
                                </div>
                                <button
                                    className={styles.CardButton} 
                                    onClick={handleButtonPercentageDiscount}
                                >
                                    <div
                                        className={styles.CardButtonTop}
                                    >
                                        <img src={ImageLogo} alt={"LogoIma"} className={styles.LogoImage} />
                                    </div>
                                    <div
                                        className={styles.CardButtonMiddle}
                                    >
                                        <span
                                            className={styles.CardButtonMiddleText}
                                        >
                                            f.e. 40% Discount
                                        </span>
                                    </div>
                                    <div
                                        className={styles.CardButtonBottom}
                                    >
                                        <div
                                            className={styles.CardButtonValid}
                                        >
                                        <span
                                            className={styles.CardButtonValidText}
                                        >
                                            01/01/2025
                                        </span>
                                        </div>
                                        <div
                                            className={styles.CardButtonCircle}
                                        >
                                            <img src={Coupon} alt={"CouponIma"} className={styles.CouponImage} />
                                        </div>
                                    </div>
                                </button>
                            </div>

                            <div
                                className={styles.MiddleCardContainer}
                            >
                                <div
                                    className={styles.CardTitleContainer}
                                >
                                    <span
                                        className={styles.CardTitleContainerText}
                                    >
                                        3. Fixed Price Discount
                                    </span>
                                </div>
                                <button
                                    className={styles.CardButton} 
                                    onClick={handleButtonFixedDiscount}
                                >
                                    <div
                                        className={styles.CardButtonTop}
                                    >
                                        <img src={ImageLogo} alt={"LogoIma"} className={styles.LogoImage} />
                                    </div>
                                    <div
                                        className={styles.CardButtonMiddle}
                                    >
                                        <span
                                            className={styles.CardButtonMiddleText}
                                        >
                                            f.e. 5$ Discount
                                        </span>
                                    </div>
                                    <div
                                        className={styles.CardButtonBottom}
                                    >
                                        <div
                                            className={styles.CardButtonValid}
                                        >
                                        <span
                                            className={styles.CardButtonValidText}
                                        >
                                            01/01/2025
                                        </span>
                                        </div>
                                        <div
                                            className={styles.CardButtonCircle}
                                        >
                                            <img src={Coupon} alt={"CouponIma"} className={styles.CouponImage} />
                                        </div>
                                    </div>
                                </button>
                            </div>

                            <div
                                className={styles.MiddleCardContainer}
                            >
                                <div
                                    className={styles.CardTitleContainer}
                                >
                                    <span
                                        className={styles.CardTitleContainerText}
                                    >
                                        4. Fixed Price
                                    </span>
                                </div>
                                <button
                                    className={styles.CardButton} 
                                    onClick={handleButtonFixedPrice}
                                >
                                    <div
                                        className={styles.CardButtonTop}
                                    >
                                        <img src={ImageLogo} alt={"LogoIma"} className={styles.LogoImage} />
                                    </div>
                                    <div
                                        className={styles.CardButtonMiddle}
                                    >
                                        <span
                                            className={styles.CardButtonMiddleText}
                                        >
                                            f.e. 1$ Item
                                        </span>
                                    </div>
                                    <div
                                        className={styles.CardButtonBottom}
                                    >
                                        <div
                                            className={styles.CardButtonValid}
                                        >
                                        <span
                                            className={styles.CardButtonValidText}
                                        >
                                            01/01/2025
                                        </span>
                                        </div>
                                        <div
                                            className={styles.CardButtonCircle}
                                        >
                                            <img src={Coupon} alt={"CouponIma"} className={styles.CouponImage} />
                                        </div>
                                    </div>
                                </button>
                            </div>

                            
                        </div>
                    </div>
                    <div className={styles.BottomContainer}>
                        <div className={styles.Line}></div>
                    </div>
                </div>
            )}




            {/* FREE Item */}



            {page === "free" && (
                <div className={styles.ModalContent}>
                    <div
                        className={styles.TopContainer}
                    >
                        <div
                            className={styles.TitleContainer}
                        >
                            <span
                                className={styles.TitleContainerText}
                            >
                                Create Coupon
                            </span>
                        </div>
                        <div
                            className={styles.Middle2Container}
                        >
                            <div
                                className={styles.Middle2ContainerTitle}
                            >
                                <span
                                    className={styles.Middle2ContainerTitleText}
                                >
                                    What Item do you want to offer for free?
                                </span>
                            </div>
                            <div
                                className={styles.Middle2ContainerInput}
                            >
                                <input
                                    type="text"
                                    placeholder="e.g. Long Black"
                                    value={inputItemValue}
                                    onChange={handleInputItemChange}
                                    className={styles.Input}
                                    autoFocus={true}
                                />
                            </div>
                            <div
                                className={styles.Middle2ContainerTitle}
                            >
                                <span
                                    className={styles.Middle2ContainerTitleText}
                                >
                                    Takeaway and/or Dine in
                                </span>
                            </div>
                            <div
                                className={styles.Middle2ContainerRulesInput}
                            >
                                <div
                                    className={`${styles.Category} ${isDineIn ? styles.Selected : ''}`}
                                    onClick={() => handleCategoryClick('Dine In')}
                                >
                                    {isDineIn?
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />  
                                    :
                                        <img src={ThumbDown} alt={"ThumbDown"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText}>
                                        {isDineIn?
                                            "Dine In"
                                        :
                                            "No Dine In"
                                        }
                                    </span>
                                </div>
                                <div
                                    className={`${styles.Category} ${isTakeaway ? styles.Selected : ''}`}
                                    onClick={() => handleCategoryClick('Take Out')}
                                >
                                    {isTakeaway?
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />  
                                    :
                                        <img src={ThumbDown} alt={"ThumbDown"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText}>
                                        {isTakeaway?
                                            "Takeaway"
                                        :
                                            "No Takeaway"
                                        }
                                    </span>
                                </div>
                            </div>
                             <div
                                className={styles.Middle5ContainerTitle}
                            >
                                <span
                                    className={styles.Middle6ContainerTitleText}
                                >
                                    How much is the original value of the free item in cents? <span className={styles.Middle6ContainerSubTitleText}>{"5 AUD = 500"}</span>
                                </span>
                            </div>
                            <div className={styles.Middle7ContainerTitle}>
                                    <input
                                        placeholder="Amount in cents"
                                        value={originalValue}
                                        onChange={handleFreeOriginalChange}
                                        className={styles.Input2}
                                        type='number'
                                    />
                            </div>
                            <div
                                className={styles.Middle2ContainerRest}
                            />
                        </div>
                    </div>
                    <div className={styles.BottomContainer}>
                        <div className={styles.Line2}/>
                        <div
                            className={styles.BottomContainer2}
                        >
                            <button 
                                type='button' 
                                className={styles.BackButton}
                                onClick={handlePreviousFree} 
                                style={{
                                    border: 'none', 
                                    outline: 'none',
                                    cursor:'pointer',
                                }}
                            >
                                <span className={styles.BackButtonText}>
                                    Back
                                </span>
                            </button>
                            <button 
                                type='button' 
                                className={isButtonDisabled ? styles.NextButtonDisabled : styles.NextButton}
                                onClick={handleNextFree} 
                                disabled={isButtonDisabled || isLoading }
                                style={{
                                    border: isButtonDisabled ? null : 'none', 
                                    outline: isButtonDisabled ? null : 'none',
                                    cursor: isButtonDisabled ? 'inherit' : 'pointer',
                                }}
                            >
                            {isLoading?
                                <Spinner 
                                color='white'
                                />
                            :
                                <span className={isButtonDisabled? styles.NextButtonTextDisabled : styles.NextButtonText}>
                                    Next
                                </span>
                            }
                            </button>
                        </div>
                    </div> 
                </div>
            )}




            {/* PERCENTAGE DISCOUNT */}




            {page === "percentageDiscount" && (
                <div className={styles.ModalContent}>
                    <div
                        className={styles.TopContainer}
                    >
                        <div
                            className={styles.TitleContainer}
                        >
                            <span
                                className={styles.TitleContainerText}
                            >
                                Create Coupon
                            </span>
                        </div>
                        <div
                            className={styles.Middle2Container}
                        >
                            <div
                                className={styles.Middle2ContainerTitle}
                            >
                                <span
                                    className={styles.Middle2ContainerTitleText}
                                >
                                    For what Item(s) do you want to offer a Discount?
                                </span>
                            </div>
                            <div
                                className={styles.Middle5ContainerInput}
                            >
                                 <div
                                    className={`${styles.Category5} ${isAllItems ? styles.Selected : ''}`}
                                    onClick={() => handleCategoryItemClick()}
                                >
                                    {isAllItems?
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />  
                                    :
                                        <img src={Todo} alt={"Todo"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText}>
                                        {isAllItems?
                                            "All Items"
                                        :
                                            "Following Items:"
                                        }
                                    </span>
                                </div>
                                {isAllItems?
                                    <>
                                    </>
                                :
                                    <input
                                        type="text"
                                        placeholder="e.g. margherita pizza, fries"
                                        value={inputItemValue}
                                        onChange={handleInputPercentageChange}
                                        className={styles.Input5}
                                        autoFocus={true}
                                    />
                                }
                            </div>
                            <div
                                className={styles.Middle2ContainerTitle}
                            >
                                <span
                                    className={styles.Middle2ContainerTitleText}
                                >
                                    Takeaway and/or Dine in
                                </span>
                            </div>
                            <div
                                className={styles.Middle2ContainerRulesInput}
                            >
                                <div
                                    className={`${styles.Category} ${isDineIn ? styles.Selected : ''}`}
                                    onClick={() => handleCategoryClick('Dine In')}
                                >
                                    {isDineIn?
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />  
                                    :
                                        <img src={ThumbDown} alt={"ThumbDown"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText}>
                                        {isDineIn?
                                            "Dine In"
                                        :
                                            "No Dine In"
                                        }
                                    </span>
                                </div>
                                <div
                                    className={`${styles.Category} ${isTakeaway ? styles.Selected : ''}`}
                                    onClick={() => handleCategoryClick('Take Out')}
                                >
                                    {isTakeaway?
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />  
                                    :
                                        <img src={ThumbDown} alt={"ThumbDown"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText}>
                                        {isTakeaway?
                                            "Takeaway"
                                        :
                                            "No Takeaway"
                                        }
                                    </span>
                                </div>
                            </div>
                            <div
                                className={styles.Middle6ContainerTitle}
                            >
                                <span
                                    className={styles.Middle6ContainerTitleText}
                                >
                                    How much is the Discount?
                                </span>
                                <span
                                    className={styles.Middle6ContainerTitleText}
                                >
                                    Is there a minimun spend?
                                </span>
                            </div>
                            <div className={styles.Middle7ContainerTitle}>
                                <div
                                    className={`${styles.Category6}`}
                                    onClick={handleDiscountPopupOpen}
                                >
                                    <span className={styles.CategoryText7}>
                                        {discountAmount?
                                            `${discountAmount}%`
                                        :
                                            "Choose Discount"
                                        }
                                    </span>
                                </div>
                                <div
                                    className={`${styles.Category7} ${isMinimumSpend ? styles.Selected7 : ''}`}
                                    onClick={handleMinimumSpend}
                                >
                                     {isMinimumSpend?
                                        <></>
                                    :
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText7}>
                                        {isMinimumSpend?
                                            `Minimum: ${minimumAmount} AUD`
                                        :
                                            `No Minimum Spend`
                                        }
                                    </span>
                                </div>
                            </div>
                            <div
                                className={styles.Middle5ContainerRest}
                            />
                        </div>
                    </div>
                    <div className={styles.BottomContainer}>
                        <div className={styles.Line2}/>
                        <div
                            className={styles.BottomContainer2}
                        >
                            <button 
                                type='button' 
                                className={styles.BackButton}
                                onClick={handlePreviousPercentageDiscount} 
                                style={{
                                    border: 'none', 
                                    outline: 'none',
                                    cursor:'pointer',
                                }}
                            >
                                <span className={styles.BackButtonText}>
                                    Back
                                </span>
                            </button>
                            <button 
                                type='button' 
                                className={isButtonDisabled ? styles.NextButtonDisabled : styles.NextButton}
                                onClick={handleNextPercentageDiscount} 
                                disabled={isButtonDisabled || isLoading }
                                style={{
                                    border: isButtonDisabled ? null : 'none', 
                                    outline: isButtonDisabled ? null : 'none',
                                    cursor: isButtonDisabled ? 'inherit' : 'pointer',
                                }}
                            >
                            {isLoading?
                                <Spinner 
                                color='white'
                                />
                            :
                                <span className={isButtonDisabled? styles.NextButtonTextDisabled : styles.NextButtonText}>
                                    Next
                                </span>
                            }
                            </button>
                        </div>
                    </div> 
                </div>
            )}



            {/* FIXED DISCOUNT */}



            {page === "fixedDiscount" && (
                <div className={styles.ModalContent}>
                    <div
                        className={styles.TopContainer}
                    >
                        <div
                            className={styles.TitleContainer}
                        >
                            <span
                                className={styles.TitleContainerText}
                            >
                                Create Coupon
                            </span>
                        </div>
                        <div
                            className={styles.Middle2Container}
                        >
                            <div
                                className={styles.Middle2ContainerTitle}
                            >
                                <span
                                    className={styles.Middle2ContainerTitleText}
                                >
                                    For what Item(s) do you want to offer a Discount?
                                </span>
                            </div>
                            <div
                                className={styles.Middle5ContainerInput}
                            >
                                 <div
                                    className={`${styles.Category5} ${isAllItems ? styles.Selected : ''}`}
                                    onClick={() => handleCategoryItemClick()}
                                >
                                    {isAllItems?
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />  
                                    :
                                        <img src={Todo} alt={"Todo"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText}>
                                        {isAllItems?
                                            "All Items"
                                        :
                                            "Following Items:"
                                        }
                                    </span>
                                </div>
                                {isAllItems?
                                    <>
                                    </>
                                :
                                    <input
                                        type="text"
                                        placeholder="e.g. margherita pizza, fries"
                                        value={inputItemValue}
                                        onChange={handleInputFixedDiscountItemChange}
                                        className={styles.Input5}
                                        autoFocus={true}
                                    />
                                }
                            </div>
                            <div
                                className={styles.Middle2ContainerTitle}
                            >
                                <span
                                    className={styles.Middle2ContainerTitleText}
                                >
                                    Takeaway and/or Dine in
                                </span>
                            </div>
                            <div
                                className={styles.Middle2ContainerRulesInput}
                            >
                                <div
                                    className={`${styles.Category} ${isDineIn ? styles.Selected : ''}`}
                                    onClick={() => handleCategoryClick('Dine In')}
                                >
                                    {isDineIn?
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />  
                                    :
                                        <img src={ThumbDown} alt={"ThumbDown"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText}>
                                        {isDineIn?
                                            "Dine In"
                                        :
                                            "No Dine In"
                                        }
                                    </span>
                                </div>
                                <div
                                    className={`${styles.Category} ${isTakeaway ? styles.Selected : ''}`}
                                    onClick={() => handleCategoryClick('Take Out')}
                                >
                                    {isTakeaway?
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />  
                                    :
                                        <img src={ThumbDown} alt={"ThumbDown"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText}>
                                        {isTakeaway?
                                            "Takeaway"
                                        :
                                            "No Takeaway"
                                        }
                                    </span>
                                </div>
                            </div>
                            <div
                                className={styles.Middle6ContainerTitle}
                            >
                                <span
                                    className={styles.Middle6ContainerTitleText}
                                >
                                    How much is the Discount?
                                </span>
                                <span
                                    className={styles.Middle6ContainerTitleText}
                                >
                                    Is there a minimun spend?
                                </span>
                            </div>
                            <div className={styles.Middle7ContainerTitle}>
                                <div
                                    className={`${styles.Category6}`}
                                    onClick={handleFixedPopupOpen}
                                >
                                    <span className={styles.CategoryText7}>
                                        {discountAmount?
                                            `${discountAmount} AUD`
                                        :
                                            "Choose Discount"
                                        }
                                    </span>
                                </div>
                                <div
                                    className={`${styles.Category7} ${isMinimumSpend ? styles.Selected7 : ''}`}
                                    onClick={handleMinimumSpend}
                                >
                                     {isMinimumSpend?
                                        <></>
                                    :
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText7}>
                                        {isMinimumSpend?
                                            `Minimum: ${minimumAmount} AUD`
                                        :
                                            `No Minimum Spend`
                                        }
                                    </span>
                                </div>
                            </div>
                            <div
                                className={styles.Middle5ContainerRest}
                            />
                        </div>
                    </div>
                    <div className={styles.BottomContainer}>
                        <div className={styles.Line2}/>
                        <div
                            className={styles.BottomContainer2}
                        >
                            <button 
                                type='button' 
                                className={styles.BackButton}
                                onClick={handlePreviousFixedDiscount} 
                                style={{
                                    border: 'none', 
                                    outline: 'none',
                                    cursor:'pointer',
                                }}
                            >
                                <span className={styles.BackButtonText}>
                                    Back
                                </span>
                            </button>
                            <button 
                                type='button' 
                                className={isButtonDisabled ? styles.NextButtonDisabled : styles.NextButton}
                                onClick={handleNextFixedDiscount} 
                                disabled={isButtonDisabled || isLoading }
                                style={{
                                    border: isButtonDisabled ? null : 'none', 
                                    outline: isButtonDisabled ? null : 'none',
                                    cursor: isButtonDisabled ? 'inherit' : 'pointer',
                                }}
                            >
                            {isLoading?
                                <Spinner 
                                color='white'
                                />
                            :
                                <span className={isButtonDisabled? styles.NextButtonTextDisabled : styles.NextButtonText}>
                                    Next
                                </span>
                            }
                            </button>
                        </div>
                    </div> 
                </div>
            )}




            {/* FIXED PRICE ITEM */}



            {page === "fixedPrice" && (
                <div className={styles.ModalContent}>
                    <div
                        className={styles.TopContainer}
                    >
                        <div
                            className={styles.TitleContainer}
                        >
                            <span
                                className={styles.TitleContainerText}
                            >
                                Create Coupon
                            </span>
                        </div>
                        <div
                            className={styles.Middle2Container}
                        >
                            <div
                                className={styles.Middle2ContainerTitle}
                            >
                                <span
                                    className={styles.Middle2ContainerTitleText}
                                >
                                    What Item do you want to offer at a fixed price?
                                </span>
                            </div>
                            <div
                                className={styles.Middle2ContainerInput}
                            >
                                <input
                                    type="text"
                                    placeholder="e.g. Long Black"
                                    value={inputItemValue}
                                    onChange={handleInputItemFixedChange}
                                    className={styles.Input}
                                    autoFocus={true}
                                />
                            </div>
                            <div
                                className={styles.Middle2ContainerTitle}
                            >
                                <span
                                    className={styles.Middle2ContainerTitleText}
                                >
                                    Takeaway and/or Dine in
                                </span>
                            </div>
                            <div
                                className={styles.Middle2ContainerRulesInput}
                            >
                                <div
                                    className={`${styles.Category} ${isDineIn ? styles.Selected : ''}`}
                                    onClick={() => handleCategoryClick('Dine In')}
                                >
                                    {isDineIn?
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />  
                                    :
                                        <img src={ThumbDown} alt={"ThumbDown"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText}>
                                        {isDineIn?
                                            "Dine In"
                                        :
                                            "No Dine In"
                                        }
                                    </span>
                                </div>
                                <div
                                    className={`${styles.Category} ${isTakeaway ? styles.Selected : ''}`}
                                    onClick={() => handleCategoryClick('Take Out')}
                                >
                                    {isTakeaway?
                                        <img src={ThumbUp} alt={"ThumbUp"} className={styles.ThumbImage} />  
                                    :
                                        <img src={ThumbDown} alt={"ThumbDown"} className={styles.ThumbImage} />   
                                    }
                                    <span className={styles.CategoryText}>
                                        {isTakeaway?
                                            "Takeaway"
                                        :
                                            "No Takeaway"
                                        }
                                    </span>
                                </div>
                            </div>
                            <div
                                className={styles.Middle6ContainerTitle}
                            >
                                <span
                                    className={styles.Middle6ContainerTitleText}
                                >
                                    How much is the fixed price in cents <span className={styles.Middle6ContainerSubTitleText}>{"5 AUD = 500"}</span>
                                </span>
                                <span
                                    className={styles.Middle6ContainerTitleText}
                                >
                                    What was the original value in cents <span className={styles.Middle6ContainerSubTitleText}>{"5 AUD = 500"}</span>
                                </span>
                            </div>
                            <div className={styles.Middle7ContainerTitle}>
                                    <input
                                        placeholder="Amount in cents"
                                        value={fixedPrice}
                                        onChange={handleFixedPriceChange}
                                        className={styles.Input2}
                                        type='number'
                                    />
                                <input
                                        type="number"
                                        placeholder="Amount in cents"
                                        value={originalValue}
                                        onChange={handleOriginalValueChange}
                                        className={styles.Input2}
                                    />
                            </div>
                            <div
                                className={styles.Middle5ContainerRest}
                            />
                        </div>
                    </div>
                    <div className={styles.BottomContainer}>
                        <div className={styles.Line2}/>
                        <div
                            className={styles.BottomContainer2}
                        >
                            <button 
                                type='button' 
                                className={styles.BackButton}
                                onClick={handlePreviousFixedPrice} 
                                style={{
                                    border: 'none', 
                                    outline: 'none',
                                    cursor:'pointer',
                                }}
                            >
                                <span className={styles.BackButtonText}>
                                    Back
                                </span>
                            </button>
                            <button 
                                type='button' 
                                className={isButtonDisabled ? styles.NextButtonDisabled : styles.NextButton}
                                onClick={handleNextFixedPrice} 
                                disabled={isButtonDisabled || isLoading }
                                style={{
                                    border: isButtonDisabled ? null : 'none', 
                                    outline: isButtonDisabled ? null : 'none',
                                    cursor: isButtonDisabled ? 'inherit' : 'pointer',
                                }}
                            >
                            {isLoading?
                                <Spinner 
                                color='white'
                                />
                            :
                                <span className={isButtonDisabled? styles.NextButtonTextDisabled : styles.NextButtonText}>
                                    Next
                                </span>
                            }
                            </button>
                        </div>
                    </div> 
                </div>
            )}



            {/* TERMS PAGE  */}




            {page === "terms" && (
               <div className={styles.ModalContent}>
                    <div
                        className={styles.TopContainer}
                    >
                        <div
                            className={styles.TitleContainer}
                        >
                            <span
                                className={styles.TitleContainerText}
                            >
                                Create Terms
                            </span>
                        </div>
                        <div
                            className={styles.Middle2Container}
                        >
                            <div
                                className={styles.Middle4ContainerTitle}
                            >
                                <span
                                    className={styles.Middle2ContainerTitleText}
                                >
                                    Terms
                                </span>
                            </div>
                            <div
                                className={styles.Middle3ContainerRest}
                            >
                                <div
                                    className={styles.Middle4ContainerTitle}
                                >
                                    <span
                                        className={styles.Middle2ContainerDate}
                                    >
                                        All coupons are valid for 20 days
                                    </span>
                                </div>
                                <div
                                    className={styles.Middle4ContainerTitle}
                                >
                                    <span
                                        className={styles.Middle2ContainerDate}
                                    >
                                        All coupons are non-combinable
                                    </span>
                                </div>
                                {showInputs && terms.map((term, index) => (
                                    <div key={index} className={styles.Middle3ContainerTitle}>
                                        <input
                                            type="text"
                                            className={styles.Middle3ContainerTerm}
                                            value={term}
                                            onChange={(e) => handleTermChange(index, e)}
                                            spellCheck="false" // Add this 
                                            autoFocus={focusTerm}
                                            style={{
                                                outline: 'none',
                                            }}
                                        />
                                            <button
                                                onClick={() => removeTerm(index)}
                                                className={styles.DeleteButton}
                                                style={{
                                                    outline: 'none',
                                                    border:'none'
                                                }}
                                            >
                                                  <img src={Waste} alt={"WasteIma"} className={styles.DeleteImage} />
                                            </button>
                                    </div>
                                ))}
                                <div
                                    className={styles.Middle4ContainerTitle}
                                >
                                    <button
                                        onClick={addTerm}
                                        className={styles.Middle3ContainerAddTermButton}
                                        style={{
                                            outline: 'none',
                                            cursor:'pointer',
                                        }}
                                    >
                                        <span>
                                            + Add Term
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.BottomContainer}>
                        <div className={styles.Line3}/>
                        <div
                            className={styles.BottomContainer2}
                        >
                            <button 
                                type='button' 
                                className={styles.BackButton}
                                onClick={handlePreviousTerms} 
                                style={{
                                    border: 'none', 
                                    outline: 'none',
                                    cursor:'pointer',
                                }}
                            >
                                <span className={styles.BackButtonText}>
                                    Back
                                </span>
                            </button>
                            <button 
                                type='button' 
                                className={isButtonDisabled ? styles.NextButtonDisabled : styles.NextButton}
                                onClick={handleNextTerms} 
                                disabled={isButtonDisabled || isLoading }
                                style={{
                                    border: isButtonDisabled ? null : 'none', 
                                    outline: isButtonDisabled ? null : 'none',
                                    cursor: isButtonDisabled ? 'inherit' : 'pointer',
                                }}
                            >
                            {isLoading?
                                <Spinner 
                                color='white'
                                />
                            :
                                <span className={isButtonDisabled? styles.NextButtonTextDisabled : styles.NextButtonText}>
                                    Next
                                </span>
                            }
                            </button>
                        </div>
                    </div> 
                </div>
            )}   

            


            {/* Emoji Page */}




            {page === "emoji" && (
                <div className={styles.ModalContent}>
                 <div
                     className={styles.TopContainer}
                 >
                     <div
                         className={styles.TitleContainer}
                     >
                         <span
                             className={styles.TitleContainerText}
                         >
                             Choose Emoji
                         </span>
                     </div>
                     <div
                         className={styles.Middle2Container}
                     >
                         <div
                             className={styles.Middle4ContainerTitle}
                         >
                             <span
                                 className={styles.Middle2ContainerTitleText}
                             >
                                 Choose an emoji that suits your coupon
                             </span>
                         </div>
                         <div
                             className={styles.Middle4ContainerRest}
                         >
                           {categories.map((category, index) => (
                            <div
                                key={index}
                                className={`${styles.CategoryEmoji} ${isCategorySelected(index) ? styles.SelectedEmoji : ''}`}
                                onClick={() => handleCategoryEmojiClick(index, category)}
                            >
                                <img src={category.image} alt={category.name} className={styles.CategoryEmojiImage} />
                            </div>
                        ))}
                         </div>
                     </div>
                 </div>
                 <div className={styles.BottomContainer}>
                     <div className={styles.Line4}/>
                     <div
                         className={styles.BottomContainer2}
                     >
                         <button 
                             type='button' 
                             className={styles.BackButton}
                             onClick={handlePreviousEmoji} 
                             style={{
                                 border: 'none', 
                                 outline: 'none',
                                 cursor:'pointer',
                             }}
                         >
                             <span className={styles.BackButtonText}>
                                 Back
                             </span>
                         </button>
                         <button 
                             type='button' 
                             className={isButtonDisabled ? styles.NextButtonDisabled : styles.NextButton}
                             onClick={handleCreateCoupon} 
                             disabled={isButtonDisabled || isLoading }
                             style={{
                                 border: isButtonDisabled ? null : 'none', 
                                 outline: isButtonDisabled ? null : 'none',
                                 cursor: isButtonDisabled ? 'inherit' : 'pointer',
                             }}
                         >
                         {isLoading?
                             <Spinner 
                             color='white'
                             />
                         :
                             <span className={isButtonDisabled? styles.NextButtonTextDisabled : styles.NextButtonText}>
                                 Next
                             </span>
                         }
                         </button>
                     </div>
                 </div> 
             </div>
            )}
        </div>
    </div>
  );
}

export default Modal;
