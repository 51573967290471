// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StorePickerTop_Main__a4InV {
    display: flex;
    /* background: white; */
    background: #fcfbf8;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    width: 100vw;
    height: 30vh;
}
.StorePickerTop_TextDiv1Black__4TdxM {
    font-weight: 500;
    font-size: 30px;
    font-family: "Rubik";
    color:#FF6EC7;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 10px;
}
.StorePickerTop_TextDiv1Gray__\\+\\+0D2 {
    font-weight: 400;
    font-size: 15px;
    font-family: "Rubik";
    color:#585b5f;
    margin-left: 50px;
    margin-right: 50px;
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}`, "",{"version":3,"sources":["webpack://./src/Components/StorePicker/StorePickerTop.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,yBAAyB;IACzB,sBAAsB;IACtB,YAAY;IACZ,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,aAAa;IACb,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;AACA;;AAEA;AACA","sourcesContent":[".Main {\n    display: flex;\n    /* background: white; */\n    background: #fcfbf8;\n    flex-wrap: nowrap;\n    align-items: center;\n    justify-content: flex-end;\n    flex-direction: column;\n    width: 100vw;\n    height: 30vh;\n}\n.TextDiv1Black {\n    font-weight: 500;\n    font-size: 30px;\n    font-family: \"Rubik\";\n    color:#FF6EC7;\n    margin-left: 50px;\n    margin-right: 50px;\n    margin-bottom: 10px;\n}\n.TextDiv1Gray {\n    font-weight: 400;\n    font-size: 15px;\n    font-family: \"Rubik\";\n    color:#585b5f;\n    margin-left: 50px;\n    margin-right: 50px;\n}\n\n@media only screen and (max-width: 600px) {\n}\n\n@media only screen and (max-width: 400px) {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Main": `StorePickerTop_Main__a4InV`,
	"TextDiv1Black": `StorePickerTop_TextDiv1Black__4TdxM`,
	"TextDiv1Gray": `StorePickerTop_TextDiv1Gray__++0D2`
};
export default ___CSS_LOADER_EXPORT___;
