import React, {useState} from 'react';
import styles from './SidebarSettings.module.css';
import { useOutletContext } from "react-router-dom";
import { Spinner } from "react-activity";
import { message } from "antd";
import { API_BASE_URL} from "../../Config/Config";
import { getToken } from "../../Helpers/Helpers";
import axios from 'axios';

function SidebarSettings() {
const { restaurantData } = useOutletContext(); 
const [restaurantABN, setRestaurantABN] = useState(restaurantData.restaurantPrivate.restaurantABN);
const [restaurantACN, setRestaurantACN] = useState(restaurantData.restaurantPrivate.restaurantACN);
const [restaurantBSB, setRestaurantBSB] = useState(restaurantData.restaurantPrivate.restaurantBSB);
const [restaurantAccountNumber, setRestaurantAccountNumber] = useState(restaurantData.restaurantPrivate.restaurantAccountNumber);
const [restaurantAccountHolderName, setRestaurantAccountHolderName] = useState(restaurantData.restaurantPrivate.restaurantAccountHolderName);
const [restaurantPhoneNumber, setRestaurantPhoneNumber] = useState(restaurantData.restaurantPrivate.restaurantPhoneNumber);
const [firstInputRestaurantPhoneNumber, setFirstInputRestaurantPhoneNumber] = useState(restaurantData.restaurantPrivate.restaurantPhoneNumber);
const [isLoading, setIsLoading] = useState(false);
const [isLoading2, setIsLoading2] = useState(false);
const [isButtonDisabled, setIsButtonDisabled] = useState(true);
const [hasInputChanged, setHasInputChanged] = useState(false);


const handleRestaurantABN = async (event) => {
    const newNameValue = event.target.value;
    setRestaurantABN(newNameValue);
    setHasInputChanged(newNameValue !== restaurantData.restaurantPrivate.restaurantABN);
  };

  const handleRestaurantACN = async (event) => {
    const newNameValue = event.target.value;
    setRestaurantACN(newNameValue);
    setHasInputChanged(newNameValue !== restaurantData.restaurantPrivate.restaurantACN);
  };

  const handleRestaurantBSB = async (event) => {
    const newNameValue = event.target.value;
    setRestaurantBSB(newNameValue);
    setHasInputChanged(newNameValue !== restaurantData.restaurantPrivate.restaurantBSB);
  };

  const handleRestaurantAccountNumber = async (event) => {
    const newNameValue = event.target.value;
    setRestaurantAccountNumber(newNameValue);
    setHasInputChanged(newNameValue !== restaurantData.restaurantPrivate.restaurantAccountNumber);
  };

  const handleRestaurantAccountHolderName = async (event) => {
    const newNameValue = event.target.value;
    setRestaurantAccountHolderName(newNameValue);
    setHasInputChanged(newNameValue !== restaurantData.restaurantPrivate.restaurantAccountHolderName);
  };


const handleRestaurantPhoneNumber = async(event) => {
    const newValue = event.target.value;
    setRestaurantPhoneNumber(newValue);
    if (newValue!== firstInputRestaurantPhoneNumber){
        setIsButtonDisabled(false) 
    }
    else {
        setIsButtonDisabled(true)
    }
};

const handleSavePayoutDetails = async() => {
    setIsLoading2(true)
    setHasInputChanged(false)
    axios.post( `${API_BASE_URL}api/update-payout-info`,{
     'restaurantABN': restaurantABN,
     'restaurantACN': restaurantACN,
     'restaurantBSB': restaurantBSB,
     'restaurantAccountNumber': restaurantAccountNumber,
     'restaurantAccountHolderName': restaurantAccountHolderName,

    },
    {headers: {
      'Authorization': `Bearer ${getToken()}`,
      // 'Content-Type': 'application/json' 
    }})
    .then(response => {
        console.log('response', response)
        setIsLoading2(false)
        message.success("Payout details saved");
    })
    .catch(error => {
      message.error("Something went wrong");
      setIsLoading(false)
      setRestaurantPhoneNumber(firstInputRestaurantPhoneNumber)
    })
}

const handleSavePhoneNumber = async() => {
    setIsLoading(true)
    setIsButtonDisabled(true)
    axios.post( `${API_BASE_URL}api/update-phonenumber-info`,{
     'restaurantPhoneNumber': restaurantPhoneNumber,

    },
    {headers: {
      'Authorization': `Bearer ${getToken()}`,
      // 'Content-Type': 'application/json' 
    }})
    .then(response => {
        setFirstInputRestaurantPhoneNumber(response.data.data)
        setRestaurantPhoneNumber(response.data.data)
        setIsLoading(false)
        setIsButtonDisabled(true)
        message.success("Phonenumber saved");
    })
    .catch(error => {
      message.error("Something went wrong");
      setIsLoading(false)
      setIsButtonDisabled(true)
      setRestaurantPhoneNumber(firstInputRestaurantPhoneNumber)
    })
}

return (
    <div
        className={styles.Main}
    >
        <div
            className={styles.TitleContainer}
        >
            <span
                className={styles.TitleContainerText}
            >
                Settings
            </span>
        </div>
        <div
            className={styles.TopInfoContainer}
        >
            <div
                className={styles.TopInfoTitleContainer}
            >
                <span
                    className={styles.TopInfoTitleContainerText}
                >
                    Business information
                </span>
                    <button 
                        type='button' 
                        className={!hasInputChanged ? styles.SaveButton : styles.SaveButtonDisabled}
                        onClick={handleSavePayoutDetails} 
                        disabled={!hasInputChanged || isLoading2 }
                        style={{
                        border: null, 
                        outline: 'none',
                        cursor: !hasInputChanged ? 'inherit' : 'pointer',
                        }}
                    >
                        {isLoading2?
                            <Spinner 
                            color='gray'
                            size='8'
                            />
                        :
                            <span className={!hasInputChanged ? styles.SaveButtonText : styles.SaveButtonTextDisabled}>
                                Save
                            </span>
                        }
                    </button>
            </div>
            <div
                className={styles.TitleInputContainer}
            >
                <span
                    className={styles.TitleInputContainerText}
                >
                    ABN number <span className={styles.TitleInputContainerTextInfo}>(11 digits)</span>
                </span>
            </div>
            <textarea
                type="text"
                value={restaurantABN}
                onChange={handleRestaurantABN}
                className={styles.TextAreaName}
                maxLength={11}
                style={{
                    outline: 'none',
                    height: '17px', // Adjust the height as needed
                    padding: '10px', // Add padding for better spacing
                    marginTop:'10px',
                    resize: 'none', // Disable textarea resizing
                    background: '#fcfbf8',
                    }}
            />
            {/* <div
                className={styles.TitleInputContainer}
            >
                <span
                    className={styles.TitleInputContainerText}
                >
                    ACN number <span className={styles.TitleInputContainerTextInfo}>(optional - 9 digits)</span>
                </span>
            </div>
            <textarea
                type="text"
                value={restaurantACN}
                onChange={handleRestaurantACN}
                className={styles.TextAreaName}
                maxLength={9}
                style={{
                    outline: 'none',
                    height: '17px', // Adjust the height as needed
                    padding: '10px', // Add padding for better spacing
                    marginTop:'10px',
                    resize: 'none', // Disable textarea resizing
                    background: '#fcfbf8',
                    }}
            />
            <div
                className={styles.TitleInputContainer}
            >
                <span
                    className={styles.TitleInputContainerText}
                >
                    BSB <span className={styles.TitleInputContainerTextInfo}>(6 digits)</span>
                </span>
            </div>
            <textarea
                type="text"
                value={restaurantBSB}
                onChange={handleRestaurantBSB}
                className={styles.TextAreaName}
                maxLength={6}
                style={{
                    outline: 'none',
                    height: '17px', // Adjust the height as needed
                    padding: '10px', // Add padding for better spacing
                    marginTop:'10px',
                    resize: 'none', // Disable textarea resizing
                    background: '#fcfbf8',
                    }}
            />
            <div
                className={styles.TitleInputContainer}
            >
                <span
                    className={styles.TitleInputContainerText}
                >
                    Account Number <span className={styles.TitleInputContainerTextInfo}>(6-10 digits)</span>
                </span>
            </div>
            <textarea
                type="text"
                value={restaurantAccountNumber}
                onChange={handleRestaurantAccountNumber}
                className={styles.TextAreaName}
                maxLength={10}
                style={{
                    outline: 'none',
                    height: '17px', // Adjust the height as needed
                    padding: '10px', // Add padding for better spacing
                    marginTop:'10px',
                    resize: 'none', // Disable textarea resizing
                    background: '#fcfbf8',
                    }}
            />
            <div
                className={styles.TitleInputContainer}
            >
                <span
                    className={styles.TitleInputContainerText}
                >
                    Account Holder Name
                </span>
            </div>
            <textarea
                type="text"
                value={restaurantAccountHolderName}
                onChange={handleRestaurantAccountHolderName}
                className={styles.TextAreaName2}
                maxLength={50}
                style={{
                    outline: 'none',
                    height: '17px', // Adjust the height as needed
                    padding: '10px', // Add padding for better spacing
                    marginTop:'10px',
                    resize: 'none', // Disable textarea resizing
                    background: '#fcfbf8',
                    }}
            /> */}
        </div>
        <div
            className={styles.MiddleInfoContainer}
        >
            <div
                className={styles.TopInfoTitleContainer2}
            >
                <span
                    className={styles.TopInfoTitleContainerText}
                >
                    Contractual Partner
                </span>
                <span
                    className={styles.TopInfoSubTitleContainerText}
                >
                    Details about the business we have registered as the contractual partner and legal seller of items on the Sustainabite platform
                </span>
            </div>
            <span
                className={styles.MiddleInfoHeadText}
            >
                Business Name
            </span>
            <span
                className={styles.MiddleInfoText}
            >
                {restaurantData.restaurantPublic.restaurantName}
            </span>
            <span
                className={styles.MiddleInfoHeadText}
            >
                Business Address
            </span>
            <span
                className={styles.MiddleInfoText}
            >
                {restaurantData.restaurantPublic.restaurantAddress}
            </span>
            <span
                className={styles.MiddleInfoHeadText}
            >
                Information/Invoices sent to
            </span>
            <span
                className={styles.MiddleInfoText}
            >
                {restaurantData.restaurantUser.email}
            </span>
            <div
                className={styles.TitleInputContainer}
            >
                <span
                    className={styles.MiddleInfoHeadText}
                >
                    Phone number
                </span>
            </div>
            <textarea
                type="text"
                value={restaurantPhoneNumber}
                onChange={handleRestaurantPhoneNumber}
                className={styles.TextAreaName2}
                maxLength={20}
                style={{
                    outline: 'none',
                    height: '17px', // Adjust the height as needed
                    padding: '10px', // Add padding for better spacing
                    marginTop:'10px',
                    resize: 'none', // Disable textarea resizing
                    background: '#fcfbf8',
                    }}
            />
            <div
                className={styles.SaveContainer}
            >
                <div className={styles.SaveButtonContainer}>
                    <button 
                        type='button' 
                        className={isButtonDisabled ? styles.SaveButton : styles.SaveButtonDisabled}
                        onClick={handleSavePhoneNumber} 
                        disabled={isButtonDisabled || isLoading }
                        style={{
                        border: isButtonDisabled ? null : 'none', 
                        outline: 'none',
                        cursor: isButtonDisabled ? 'inherit' : 'pointer',
                        }}
                    >
                        {isLoading?
                            <Spinner 
                            color='gray'
                            size='8'
                            />
                        :
                            <span className={isButtonDisabled ? styles.SaveButtonText : styles.SaveButtonTextDisabled}>
                                Save
                            </span>
                        }
                    </button>
                </div>
            </div>
        </div>
    </div>
);
}

export default SidebarSettings;