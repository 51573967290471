import React, {useEffect, useState} from "react";
import styles from "./MyMarketDashboard.module.css";
import { message } from "antd";
import Loading from "../../Components/Loading/Loading";
import { useAuthContext } from "../../Context/AuthContext";
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from "../../Components/Sidebar/Sidebar";
import BagModal from "../../Components/Modal/BagModal";
import OddsModal from "../../Components/Modal/OddsModal";
import TopBarDashboard from "../../Components/TopBarDashboard/TopBarDashboard";
import { Outlet } from "react-router-dom"
import { removeToken, getToken } from "../../Helpers/Helpers";
import { API_BASE_URL} from "../../Config/Config";
import axios from 'axios';


function MyMarketDashboard() {
const [activeComponent, setActiveComponent] = useState('Dashboard');
const [isSidebarOpen, setIsSidebarOpen] = useState(true);
const [initialSidebarState, setInitialSidebarState] = useState(true);
const [isLoading, setIsLoading] = useState(true);
const [restaurantData, setRestaurantData] = useState(null);
const [allRestaurantData, setAllRestaurantData] = useState([]);
const [coupons, setCoupons] = useState([]);
const [isBagModalOpen, setIsBagModalOpen] = useState(false);
const [isOddsModalOpen, setIsOddsModalOpen] = useState(false);
const [windowWidth, setWindowWidth] = useState(window.innerWidth);

// const [activeComponent, setActiveComponent] = useState('Dashboard');


const { id } = useParams();
const { user } = useAuthContext();
const navigate = useNavigate();

useEffect(() => {
  onFetchUser()
  if(user === undefined){
    navigate( "/MyMarket", { replace: true })
  }
  else{
    if (user.id !== Number(id)) {
      navigate( "/MyMarket", { replace: true })
    } 
  }
}, [user, id, navigate]);

const onFetchUser = () => {
  axios.get(`${API_BASE_URL}api/get-info`, {
    headers: {
      'Authorization': `Bearer ${getToken()}`
    }
  })
  .then(response => {
    console.log('nico', response.data)
    if (Array.isArray(response.data) && response.data.length > 0) {
      setAllRestaurantData(response.data);
      setRestaurantData(response.data[0]);
      axios
      .post(`${API_BASE_URL}api/get-coupons-length`,{
        'restaurantId': response.data[0].restaurantPublic.id
      },
      {headers: {
          'Authorization': `Bearer ${getToken()}`}}        
      )
      .then(response => {
        setCoupons(response.data.data)
        setIsLoading(false);
      })
      .catch(error => {
          setIsLoading(false);
          message.error("No Connection");
      }); 
    } else {
      setAllRestaurantData([response.data]);
      setRestaurantData(response.data);
      axios
      .post(`${API_BASE_URL}api/get-coupons-length`,{
        'restaurantId': response.data.restaurantPublic.id
      },
      {headers: {
          'Authorization': `Bearer ${getToken()}`}}        
      )
      .then(response => {
        setCoupons(response.data.data)
        setIsLoading(false);
      })
      .catch(error => {
          setIsLoading(false);
          message.error("No Connection");
      }); 
    }
  })
  .catch(error => {
    setIsLoading(false);
    message.error("No Connection");
  });
}


// const handleClick = () => {
//   // Perform the action with data, if needed
//   console.log('Function called in child component with data:');
//   message.success("Created Surprise Bag");
// };

const closeModal = () => {
  onFetchUser()
  setIsBagModalOpen(false);
};

const closeOddsModal = () => {
  onFetchUser()
  setIsOddsModalOpen(false);
};

useEffect(() => {
  if (windowWidth < 768) {
    setIsSidebarOpen(false);
  }
}, []);

useEffect(() => {
  window.addEventListener("resize", handleResize);
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, [initialSidebarState, windowWidth]);

const toggleSidebar = () => {
  setIsSidebarOpen(!isSidebarOpen);
};

const handleComponentClick = () => {
  if (windowWidth < 768) {
    setIsSidebarOpen(false);
  }
};

// const toggleBell = () => {
// onFetchUser()
// };

const handleLogout = () => {
  removeToken();
  navigate("/MyMarket", { replace: true });
};

const handleResize = () => {
  const newWidth = window.innerWidth;
  setWindowWidth(newWidth);

  if (newWidth < 768) {
    setIsSidebarOpen(false);
    if (initialSidebarState) {
      setInitialSidebarState(false);
    }
  } else {
    setIsSidebarOpen(true);
  }
};

const handleClick = () => {
  setIsBagModalOpen(true)
};

const handleOddsClick = () => {
  setIsOddsModalOpen(true)
};

const handleSetSidebar = () => {
  console.log('siderbar')
  setActiveComponent('Coupon')
};

if (!user) {
  // Handle the case when user is not authorized to view this dashboard
  return (
    <Loading/>
  );
}
if (user === undefined) {
  // Handle the case where 'user' is explicitly undefined
  return (
    <Loading/>
  );
}

  return (
    <div className={styles.Dashboard}>
      <TopBarDashboard 
        toggleSidebar={toggleSidebar} 
        restaurantName={restaurantData && restaurantData.restaurantPublic ? restaurantData.restaurantPublic.restaurantName : ''}
        restaurantSuburb={restaurantData && restaurantData.restaurantPublic ? restaurantData.restaurantPublic.restaurantSuburb : ''}
        handleLeftButtonClick={() =>toggleSidebar()}
      />
      {isBagModalOpen && 
        <BagModal 
        onClose={closeModal} 
        restaurantId={restaurantData && restaurantData.restaurantPublic ? restaurantData.restaurantPublic.id : ''}
        />
      }
      {isOddsModalOpen && 
        <OddsModal 
        onClose={closeOddsModal} 
        coupons={coupons} 
        restaurantId={restaurantData && restaurantData.restaurantPublic ? restaurantData.restaurantPublic.id : ''}
        />
        }
      {isSidebarOpen && (
        <Sidebar
          activeComponent={activeComponent}
          setActiveComponent={setActiveComponent}
          handleLogout={handleLogout}
          handleComponentClick={handleComponentClick}
        />
      )}
      <div className={styles.Content}>
      {isLoading?
        <Loading/>
      :
        <Outlet context={{ restaurantData, coupons, handleClick, handleOddsClick, handleSetSidebar }}/>
      }
      </div>
    </div>
  );
}

export default MyMarketDashboard;