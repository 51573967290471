import React from "react";
import styles from "./HomePageP3.module.css";
import SPScheme from "../../Images/SPScheme.svg";
import CheckBox from "../../Images/CheckBox.svg";


function HomePageP3() {
  return (
   <div className={styles.Main}>
    
        <div className={styles.DivU950}>
              <div className={styles.DivU950A}>
                <span className={styles.DivU950AText}>
                  Designed for payroll
                </span>
                <span className={styles.DivU950BText}>
                  Seamlessly track and manage employee expenses with accredited open banking integrations.
                </span>
              </div>
              <div className={styles.DivU950A}>
                <span className={styles.DivU950AText}>
                  Better, Cheaper, Simpler
                </span>
                <span className={styles.DivU950BText}>
                  Up to 80% cheaper, <br/>automated compliance. <br/> SalaryPie simplifies salary packaging
                </span>
              </div>
        </div>

        <div className={styles.Div1}>
          <img src={SPScheme} alt="SPScheme" className={styles.SPScheme} />
        </div>

        <a 
            className={styles.DivContact}
            href="https://calendly.com/salary-pie" // Replace with your Calendly URL
            target="_blank" 
            rel="noopener noreferrer" 
        >
            <span className={styles.TextDivContact}>
                Get Started Today
            </span>
        </a>

        <div className={styles.Div2}>
          <div className={styles.Div2A}>
            <span className={styles.Div2AText}>
              Designed for payroll
            </span>
            <span className={styles.Div2BText}>
              Seamlessly track and manage employee expenses with accredited open banking integrations.
            </span>
          </div>
          <div className={styles.Div2B}>
            <span className={styles.Div2AText}>
              Better, Cheaper, Simpler
            </span>
            <span className={styles.Div2BText}>
              Up to 80% cheaper, <br/>automated compliance. <br/> SalaryPie simplifies salary packaging
            </span>
          </div>
          <div className={styles.Div2C}>
            <span className={styles.Div2AText}>
              No More Cards or Receipts
            </span>
            <span className={styles.Div2BText}>
              Employees no longer need special salary packaging cards or keep receipts. 
            </span>
            <span className={styles.Div2BText}>
              With SalaryPie, everything is done automatically by their everyday card.
            </span>
          </div>
        </div>
        <div className={styles.Div3}>
          <div
            className={styles.Div3A}
          >
            <span
              className={styles.Div3AText}
            >
              1-20 Employees
            </span>
            <span
              className={styles.Div3BText}
            >
              $10
            </span>
            <span
              className={styles.Div3CText}
            >
              Per employee/month
            </span>
            <span
              className={styles.Div3DText}
            >
              For Small NFP Organisations
            </span>
            <span
              className={styles.Div3EText}
            >
              <img src={CheckBox} alt="Checkbox" className={styles.CheckBox} />
              ATO compliant
            </span>
            <span
              className={styles.Div3EText}
            >
              <img src={CheckBox} alt="Checkbox" className={styles.CheckBox} />
              Up to 80% cheaper
            </span>
            <span
              className={styles.Div3EText}
            >
              <img src={CheckBox} alt="Checkbox" className={styles.CheckBox} />
              Maximises every benefit
            </span>
            <span
              className={styles.Div3EText}
            >
              <img src={CheckBox} alt="Checkbox" className={styles.CheckBox} />
              Never over the FBT limit
            </span>
          </div>



          <div
            className={styles.Div3A}
          >
            <span
              className={styles.Div3AText}
            >
              20+ Employees
            </span>
            <span
              className={styles.Div3BText}
            >
              $8
            </span>
            <span
              className={styles.Div3CText}
            >
              Per employee/month
            </span>
            <span
              className={styles.Div3DText}
            >
              For Medium/Large NFP Organisations
            </span>
            <span
              className={styles.Div3EText}
            >
              <img src={CheckBox} alt="Checkbox" className={styles.CheckBox} />
              ATO compliant
            </span>
            <span
              className={styles.Div3EText}
            >
              <img src={CheckBox} alt="Checkbox" className={styles.CheckBox} />
              Up to 80% cheaper
            </span>
            <span
              className={styles.Div3EText}
            >
              <img src={CheckBox} alt="Checkbox" className={styles.CheckBox} />
              Maximises every benefit
            </span>
            <span
              className={styles.Div3EText}
            >
              <img src={CheckBox} alt="Checkbox" className={styles.CheckBox} />
              Never over the FBT limit
            </span>
          </div>

          <div className={styles.Div4}>
            <span className={styles.Div4AText}>
              No More Cards or Receipts
            </span>
            <span className={styles.Div4BText}>
              Employees no longer need special salary packaging cards or keep receipts. 
            </span>
            <span className={styles.Div4BText}>
            With SalaryPie, everything is done automatically by their everyday card.
            </span>
            <a 
                className={styles.Div4C}
                href="https://calendly.com/salary-pie" // Replace with your Calendly URL
                target="_blank" 
                rel="noopener noreferrer" 
            >
                <span className={styles.Div4CText}>
                  See how it works
                </span>
            </a>
          </div>

        </div>
   </div>
  );
}

export default HomePageP3;