import React from "react";
import styles from "./Home.module.css";
import TopBarHome from "../../Components/TopBarHome/TopBarHome";
import HomePageOG from "../../Components/HomePageOG/HomePageOG";

function Home() {
  return (
    <div className={styles.App}>
      <HomePageOG/>
    </div>
  );
}

export default Home;