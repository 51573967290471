import React from "react";
import styles from "./Home.module.css";
import HomePageOG from "../../Components/HomePageOG/HomePageOG";
import HomePageP1 from "../../Components/HomePageP1/HomePageP1";
import HomePageP2 from "../../Components/HomePageP2/HomePageP2";
import HomePageP3 from "../../Components/HomePageP3/HomePageP3";
import HomePageP4 from "../../Components/HomePageP4/HomePageP4";
import HomePageP5 from "../../Components/HomePageP5/HomePageP5";

function Home() {
  return (
    <div className={styles.App}>
      <HomePageP1/>
      <HomePageP2/>
      <HomePageP3/>
      <HomePageP4/>
      <HomePageP5/>
    </div>
  );
}

export default Home;