import React from "react";
import styles from "./Footer.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";


function Footer() {
  return (
   <div className={styles.Main}>
       <div className={styles.BorderLine}/>
        <div className={styles.Div1}>
            <div className={styles.Div1Header}>
               <span className={styles.TextDiv1Header}>
                    Resources
                </span>
            </div>
            <div className={styles.Div2Header}>
               <a className={styles.TextDiv1Options} href="mailto:nicolas@sustainabite.com.au" target="_blank" rel="noopener noreferrer">
                    Contact
                </a>
            </div>
            <div className={styles.Div3Header}>
                <Link to="/Terms" className={styles.Link}>
                    <span className={styles.TextDiv1Options}>
                        Legal
                    </span>
                </Link>
            </div>
            <div className={styles.Div4Header}>
                <Link to="/Privacy" className={styles.Link}>
                    <span className={styles.TextDiv1Options}>
                        Privacy Center
                    </span>
                </Link>
            </div>
            <div className={styles.Div5Header}>
                <Link to="/MyMarket" className={styles.Link}>
                    <span className={styles.TextDiv1Options}>
                        MyMarket
                    </span>
                </Link>
            </div>
            <div className={styles.DivRestHeader}/>
            <div className={styles.Div6Header}>
                <div className={styles.BorderLine2}/>
                <div className={styles.Div6SubHeader}>
                    <span className={styles.TextDiv6}>
                    © Sustainabite
                    </span>
                </div>
            </div>
        </div>
   </div>
  );
}

export default Footer;