import React from "react";
import styles from "./HomePageP1.module.css";
import LogoSVG from "../../Images/LogoSVG.svg";  // Add this import
import LogoTitle from "../../Images/SalaryPie.svg";

function HomePageP1() {
  return (
   <div className={styles.Main}>
        <div className={styles.Div1}>
            <div className={styles.LeftGroup}>
                <div className={styles.DivLogo}>
                    <img src={LogoSVG} alt="Logo" className={styles.Logo} />
                </div>
                <div className={styles.DivLogoTitle}>
                    <img src={LogoTitle} alt="LogoTitle" className={styles.LogoTitle} />
                </div>
            </div>
            <div className={styles.RightGroup}>
                <div className={styles.DivMoney}>
                    <span className={styles.TextDivMoney1}>
                        $8
                    </span>
                    <span className={styles.TextDivMoney2}>
                        Employee / Month
                    </span>
                </div>
                <a 
                    className={styles.DivContact}
                    href="https://calendly.com/salary-pie" // Replace with your Calendly URL
                    target="_blank" 
                    rel="noopener noreferrer" 
                >
                    <span className={styles.TextDivContact}>
                        Talk to us
                    </span>
                </a>
            </div>
        </div>
   </div>
  );
}

export default HomePageP1;