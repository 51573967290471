import React from "react";
import styles from "./StoreTagTop.module.css";

function StoreTagTop() {
  return (
   <div className={styles.Main}>
        <span className={styles.TextDiv1Black}>
        Check your details
        </span>
        <span className={styles.TextDiv1Gray}>
        View the information below and check if it is correct. 
        <span className={styles.TextDiv1GrayImportant}> Scroll down</span> and pick the type(s)<span className={styles.TextDiv1GrayImportant}> minimum 1 - maximum 3</span> that suits your store best.
        </span>
        <span className={styles.TextDiv2Black}>
            Name, Address and Location of your store
        </span>
   </div>
  );
}

export default StoreTagTop;