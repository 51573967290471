import React, {useEffect, useState} from "react";
import styles from "./MyMarketTag.module.css";
import Loading from "../../Components/Loading/Loading";
import StoreTagTop from "../../Components/StoreTagTop/StoreTagTop";
import StoreTagMiddle from "../../Components/StoreTagMiddle/StoreTagMiddle";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { useAuthContext } from "../../Context/AuthContext";
import { Spinner } from "react-activity";
import { API_BASE_URL} from "../../Config/Config";
import { getToken } from "../../Helpers/Helpers";
import axios from 'axios';

function MyMarketTag() {
const [isButtonDisabled, setButtonDisabled] = useState(true);
const [isLoading, setIsLoading] = useState(false);
const { id } = useParams();
const { user } = useAuthContext();
const navigate = useNavigate();
const { state } = useLocation();
const [selectedCategories, setSelectedCategories] = useState([]);

const categories = [
  "Dessert",
  "Burger",
  "Drinks",
  "Pizza",
  "Italian",
  "Healthy",
  "Coffee",
  "Breakfast",
  "Vietnamese",
  "Patisserie",
  "Brunch",
  "Asian",
  "Chinese",
  "Sushi",
  "Japanese",
  "Indian",
  "Mexican",
  "Pasta",
  "Other"
];

useEffect(() => {
  setButtonDisabled(selectedCategories.length === 0);
}, [selectedCategories]);

useEffect(() => {
  if(user === undefined){
    navigate( "/MyMarket", { replace: true })
  }
  else{
    if (user.id !== Number(id)) {
      navigate( "/MyMarket", { replace: true })
    } 
  }
}, [user, id , navigate]);

const onClickNext = () => {
    setIsLoading(true)
    setButtonDisabled(true)
    axios.post( `${API_BASE_URL}api/create-restaurant`,{
     'restaurantTag': selectedCategories,
     'restaurantName': state.name,
     'restaurantDescription': state.description,
     'restaurantAddress': state.address,
     'restaurantLongitude': state.lng,
     'restaurantRating': state.rating,
     'restaurantLatitude': state.lat,
     'restaurantTimes': state.openingHours,
     'restaurantSuburb': state.suburb,
     'restaurantWebsite': state.website,
     'restaurantPhoneNumber': state.phoneNumber
    },
    {headers: {
      'Authorization': `Bearer ${getToken()}`,
      // 'Content-Type': 'application/json' 
    }})
    .then(response => {
      const userId = response.data.userId;
      navigate( `/MyMarketDashboard/${userId}/${response.data.restaurantId}/Dashboard`, { replace: true })
    })
    .catch(error => {
      setIsLoading(false)
      setButtonDisabled(false)
    })
}
   

const handleCategoryClick = (category) => {
  if (selectedCategories.includes(category)) {
    // Category is already selected, so remove it from the selectedCategories array
    setSelectedCategories(selectedCategories.filter((selectedCategory) => selectedCategory !== category));
  } else if (selectedCategories.length < 3) {
    // Category is not selected and maximum selection limit is not reached, so add it to the selectedCategories array
    setSelectedCategories([...selectedCategories, category]);
  }
};

const isCategorySelected = (category) => selectedCategories.includes(category);


const onClickBack = () => {
  navigate(-1)
};


if (!user) {
  // Handle the case when user is not authorized to view this dashboard
  return (
    <Loading/>
  );
}
if (user === undefined) {
  // Handle the case where 'user' is explicitly undefined
  return (
    <Loading/>
  );
}

  return (
    <div className={styles.App}>
      <div
        className={styles.BackButtonContainer}
      >
        <button 
            type='button' 
            className={styles.BackButton}
            onClick={onClickBack} 
            style={{
            border: 'none', 
            outline:'none',
            cursor:'pointer',
            }}
        >
            <span className={styles.BackButtonText}>
                Back
            </span>
        </button>
      </div>
     <StoreTagTop/>
     <div
          className={styles.ImageContainer}
     >
          <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${state.lat},${state.lng}&zoom=15&size=300x250&markers=color:red%7Clabel:A%7C${state.lat},${state.lng}&key=AIzaSyCZWM3pfwy68mvMkmOn_I7kY49rwz04-GQ`} alt="static map" />
     </div>
     <StoreTagMiddle
        Name={state.name}
        Address={state.address}
        Description={state.description}
     />
     <div 
      className={styles.OptionContainer}
     >
     {categories.map((category, index) => (
        <div
          key={index}
          className={`${styles.Category} ${isCategorySelected(category) ? styles.Selected : ''}`}
          onClick={() => handleCategoryClick(category)}
        >
          <span  
            className={styles.CategoryText}
          >
              {category}
          </span>
        </div>
      ))}
     </div>
     <div className={styles.BottomBar}>
      <button 
          type='button' 
          className={isButtonDisabled ? styles.NextButton : styles.NextButtonDisabled}
          onClick={onClickNext} 
          disabled={isButtonDisabled || isLoading }
          style={{
          border: isButtonDisabled ? null : 'none', 
          outline: isButtonDisabled ? null : 'none',
          cursor: isButtonDisabled ? 'inherit' : 'pointer',
          }}
      >
          {isLoading?
              <Spinner 
              color='#FF6EC7'
              />
          :
              <span className={isButtonDisabled ? styles.NextButtonText : styles.NextButtonTextDisabled}>
                  Next
              </span>
          }
        </button>
      </div>
    </div>
  );
}

export default MyMarketTag;