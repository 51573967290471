import React, {useState, useEffect} from "react";
import styles from "./MyMarketDashboardSearchStore.module.css";
import { FaTimes } from 'react-icons/fa';
import { Spinner } from "react-activity";


function MyMarketDashboardSearchStore({onClickNext, onStoreInfoChange}) {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [autocompleteService, setAutocompleteService] = useState(null);
    const [placesService, setPlacesService] = useState(null);
    const [hoveredResult, setHoveredResult] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [storeInfo, setStoreInfo] = useState([]);
  
    useEffect(() => {
      // Initialize the Google Places AutocompleteService
      setAutocompleteService(new window.google.maps.places.AutocompleteService());
      // Initialize the Google Places Service
      setPlacesService(new window.google.maps.places.PlacesService(document.createElement('div')));
    }, []);

    onStoreInfoChange(storeInfo);

    const componentRestrictions = { country: 'AU' };
    const desiredTypes = ['restaurant', 'bakery', 'cafe', 'convenience_store', 'meal_delivery' ];
    // const desiredTypes = ['establishment', 'bakery', 'bar', 'cafe', 'convenience_store', 'meal_delivery', 'meal_takeaway', 'restaurant', 'supermarket' ];
  
    const handleClearInput = () => {
      setQuery(''); // Clear the input value
      setResults([]); // Clear the autocomplete results
    };

    const handleChange = (e) => {
        const newQuery = e.target.value;
        setQuery(newQuery);
      
        if (newQuery.length >= 3) {
          // Use the AutocompleteService to get predictions with the specified types
          autocompleteService.getPlacePredictions(
            { input: newQuery, types: desiredTypes, componentRestrictions },
            (predictions, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setResults(predictions);
              } else {
                console.error('Error fetching predictions:', status);
              }
            }
          );
        } else {
          setResults([]);
        }
      };
      
      const addData = (restaurantInfo, description) => {
        const newData = {
          name: restaurantInfo.name,
          address: restaurantInfo.address,
          lat: restaurantInfo.lat,
          lng: restaurantInfo.lng,
          description: description,
          rating: restaurantInfo.rating,
          openingHours: restaurantInfo.openingHours,
          suburb: restaurantInfo.suburb,
          website: restaurantInfo.website,
          phoneNumber: restaurantInfo.phoneNumber
        };

        console.log('restaurantinfo', restaurantInfo)
    
        // Use the spread operator to create a new array with the added data
        setStoreInfo(newData);
      };

    const handlePlaceSelect = (placeId, description) => {
      // Use the PlacesService to fetch details for the selected place
      placesService.getDetails({ placeId, fields: ['name', 'formatted_address', 'geometry', 'website', 'photos', 'reviews', 'opening_hours', 'rating', 'address_components', 'formatted_phone_number'] }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setQuery(description);
          setIsButtonDisabled(false);

          const addressComponents = place.address_components;
          let suburb = '';
          for (const component of addressComponents) {
              if (component.types.includes('locality') || component.types.includes('administrative_area_level_2')) {
                  suburb = component.long_name;
              }
              else if (component.types.includes('sublocality') || component.types.includes('sublocality_level_1') || component.types.includes('sublocality_level_2')) {
                  suburb = component.long_name;
              }
          }

          const openingHours = place.opening_hours ? place.opening_hours.weekday_text : [];
          const website = place.website || '';
          const phoneNumber = place.formatted_phone_number|| '';
          const photos = place.photos || [];
          const rating = place.rating || 0;
    
          // Extract the information you need from the place object
          const restaurantInfo = {
            name: place.name,
            address: place.formatted_address,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            website: website,
            photos: photos,
            openingHours: openingHours,
            rating: rating,
            suburb: suburb,
            components: place.address_components,
            phoneNumber: phoneNumber,
          };
    
          addData(restaurantInfo, description);
          setResults([]);
        } else {
          console.error('Error fetching place details:', status);
        }
      });
    };

    const handleResultHover = (resultDescription) => {
      setHoveredResult(resultDescription);
    };
  
    return (
      <div
        className={styles.Main}
      >
        <div
          className={styles.MainInputContainer}
        >
          <input
            type="text"
            placeholder="Search..."
            value={query}
            onChange={handleChange}
            className={styles.Input}
          />
          <div className={styles.ClearButtonContainer}>
          {query && (
            <button onClick={handleClearInput} className={styles.ClearButton}>
              <FaTimes
                size="15px"
                className={styles.IconOutline}
                color='gray'
              />
            </button>
          )}
          </div>
        </div>
        <ul 
          className={styles.ResultList}
        >
          {results.map((result, place) => (
            <li
              key={result.place_id}
              onClick={() => {
                handlePlaceSelect(result.place_id, result.description);
              }}
              onMouseEnter={() => handleResultHover(result.description)}
              onMouseLeave={() => handleResultHover(null)}
              className={result.description === query ? styles.Selected : ''}
            >
              {result.description}
            </li>
          ))}
        </ul>
        <div
          className={styles.BottomContainer}
        >
          <a className={styles.TextCantFind} href="mailto:nicolas@sustainabite.com.au" target="_blank" rel="noopener noreferrer">
            I can't find my store
          </a>
          <button 
                    type='button' 
                    className={isButtonDisabled ? styles.NextButton : styles.NextButtonDisabled}
                    // onClick={Next} 
                    onClick={onClickNext} 
                    disabled={isButtonDisabled || isLoading }
                    style={{
                    border: isButtonDisabled ? null : 'none', 
                    outline: isButtonDisabled ? null : 'none',
                    cursor: isButtonDisabled ? 'inherit' : 'pointer',
                    }}
                >
                    {isLoading?
                        <Spinner 
                        color='#FF6EC7'
                        />
                    :
                        <span className={isButtonDisabled ? styles.NextButtonText : styles.NextButtonTextDisabled}>
                            Next
                        </span>
                    }
              </button>
        </div>
      </div>
    );
  };

export default MyMarketDashboardSearchStore;
