// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_App__Bds0R {
    text-align: center;
    background-color: red;
    flex-direction: column;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Home/Home.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;IACtB,aAAa;AACjB","sourcesContent":[".App {\n    text-align: center;\n    background-color: red;\n    flex-direction: column;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": `Home_App__Bds0R`
};
export default ___CSS_LOADER_EXPORT___;
