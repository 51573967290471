import React, {useState, useEffect} from 'react';
import styles from './SidebarCoupon.module.css';
import { useOutletContext } from "react-router-dom";
import { Spinner } from "react-activity";
import { message } from "antd";
import Coupon from "../../Images/Coupon.png";
import { API_BASE_URL} from "../../Config/Config";
import { getToken } from "../../Helpers/Helpers";
import ImageLogo from "../../Images/Sus-a-bite-White.png";
import axios from 'axios';
import ImageLogout from "../../Images/Logout.png";
import ThumbUp from "../../Images/ThumbUpEmoji.png";
import ThumbDown from "../../Images/ThumbDownEmoji.png";
import Point from "../../Images/PointEmoji.png";
import Pencil from "../../Images/PencilEmoji.png";
import Burger from "../../Images/HamburgerEmoji.png";
import Pizza from "../../Images/PizzaEmoji.png";
import Dessert from "../../Images/DessertEmoji.png";
import Sushi from "../../Images/SushiEmoji.png";
import Salad from "../../Images/SaladEmoji.png";
import Pasta from "../../Images/PastaEmoji.png";
import Egg from "../../Images/EggEmoji.png";
import Bagel from "../../Images/BagelEmoji.png";
import Avocado from "../../Images/AvocadoEmoji.png";
import Fries from "../../Images/FriesEmoji.png";
import Cake from "../../Images/CakeEmoji.png";
import Taco from "../../Images/TacoEmoji.png";
import Burrito from "../../Images/BurritoEmoji.png";
import Ramen from "../../Images/RamenEmoji.png";
import Pancakes from "../../Images/PancakesEmoji.png";
import Sandwich from "../../Images/SandwichEmoji.png";
import Croissant from "../../Images/CroissantEmoji.png";
import Coffee from "../../Images/CoffeeEmoji.png";
import BubbleTea from "../../Images/BubbleTeaEmoji.png";
import Donut from "../../Images/DonutEmoji.png";
import Beer from "../../Images/BeerEmoji.png";
import Wine from "../../Images/WineEmoji.png";
import TakeOut from "../../Images/TakeOutEmoji.png";
import Greece from "../../Images/GreeceEmoji.png";
import Indonesia from "../../Images/IndonesiaEmoji.png";
import Malaysia from "../../Images/MalaysiaEmoji.png";
import Vietnam from "../../Images/VietnamEmoji.png";
import Nepal from "../../Images/NepalEmoji.png";
import India from "../../Images/IndiaEmoji.png";
import Italy from "../../Images/ItalyEmoji.png";
import Cross from "../../Images/CrossEmoji.png";
import { faZ } from '@fortawesome/free-solid-svg-icons';

function SidebarCoupon() {
const {handleClick, handleOddsClick, coupons} = useOutletContext();


const handleDeleteRow = (itemId) => {
    console.log('delete', itemId); // Log the item.id
    // Implement your delete logic here
};


const categoryImages = {
    'Burger': Burger,
    'Pizza': Pizza,
    'Dessert': Dessert,
    'Sushi': Sushi,
    'Salad': Salad,
    'Pasta': Pasta,
    'Egg': Egg,
    'Bagel': Bagel,
    'Avocado': Avocado,
    'Fries': Fries,
    'Cake': Cake,
    'Taco': Taco,
    'Burrito': Burrito,
    'Ramen': Ramen,
    'Pancakes': Pancakes,
    'Sandwich': Sandwich,
    'Croissant': Croissant,
    'Coffee': Coffee,
    'BubbleTea': BubbleTea,
    'Donut': Donut,
    'Beer': Beer,
    'Wine': Wine,
    'TakeOut': TakeOut,
    'Greece': Greece,
    'Indonesia': Indonesia,
    'Malaysia': Malaysia,
    'Vietnam': Vietnam,
    'Nepal': Nepal,
    'India': India,
    'Italy': Italy,
  };

return (
    <div
        className={styles.Main}
    >
        <div
            className={styles.TitleContainer}
        >
            <div
                className={styles.TitleView}
            >
                <span
                    className={styles.TitleContainerText}
                >
                    Coupon
                </span>
            </div>
        </div>
        <div className={coupons && coupons.length > 0  ? styles.CreateBagContainer3 : styles.CreateBagContainer4}>



            {/* Create Coupon */}


            

            <button
                className={styles.CreateCouponContainer2}
                onClick={handleClick}
            >
                <div
                    className={styles.CardButton1} 
                >
                    <div
                        className={styles.CardButton1Top}
                    >
                        <img src={ImageLogo} alt={"LogoIma"} className={styles.LogoImage} />
                    </div>
                    <div
                        className={styles.CardButton1Middle}
                    >
                        <span
                            className={styles.CardButton2MiddleText}
                        >
                           + Create Coupon
                        </span>
                    </div>
                    <div
                        className={styles.CardButton1Bottom}
                    >
                        <div
                            className={styles.CardButton1Circle}
                        >
                            <img src={Coupon} alt={"CouponIma"} className={styles.CouponImage} />
                        </div>
                    </div>
                </div>
                <div
                    className={styles.CreateCouponContainerBottom}
                >
                    <span
                        className={styles.CreateCouponContainerBottomText}
                    >
                        Create new coupon
                    </span>
                </div>
            </button>



            {coupons && coupons.length > 0 ?
            <>
            <img src={Point} alt={"PointIma"} className={styles.PointImage} />
            {/* Activate coupon */}



            <button
                className={styles.CreateCouponContainer2}
                onClick={handleOddsClick}
            >
                <div
                    className={styles.CardButton} 
                >
                    <div
                        className={styles.CardButton1Top}
                    >
                        <img src={ImageLogo} alt={"LogoIma"} className={styles.LogoImage} />
                    </div>
                    <div
                        className={styles.CardButton1Middle}
                    >
                        <span
                            className={styles.CardButton2MiddleText}
                        >
                           Activate Coupon
                        </span>
                    </div>
                    <div
                        className={styles.CardButton1Bottom}
                    >
                        <div
                            className={styles.CardButtonCircle}
                        >
                            <img src={Coupon} alt={"CouponIma"} className={styles.CouponImage} />
                        </div>
                    </div>
                </div>
                <div
                    className={styles.CreateCouponContainerBottom}
                >
                    <span
                        className={styles.CreateCouponContainerBottomText}
                    >
                        Activate created coupons
                    </span>
                </div>
            </button>
            </>
            :
            null
            }
        </div>
        {coupons && coupons.length > 0?
        <>
        <div
            className={styles.TitleContainer2}
        >
            <div
                className={styles.TitleView}
            >
                <span
                    className={styles.TitleContainerText}
                >
                    Coupon Overview
                </span>
            </div>
        </div>
        </>
        :
        null
        }
         {coupons && coupons.length > 0 ?
        <>
         <div
            className={styles.TopInfoContainer}
        >
            <div
                className={styles.TopInfoTitleContainer2}
            >
                <div
                    className={styles.OverviewTitle1}
                >
                    <span
                        className={styles.TopInfoTitleContainerText3}
                    >
                        Coupon
                    </span>
                </div>
                <div
                    className={styles.OverviewTitle2}
                >
                    <span
                        className={styles.TopInfoTitleContainerText3}
                    >
                        Emoji
                    </span>
                </div>
                <div
                    className={styles.OverviewTitle3}
                >
                    <span
                        className={styles.TopInfoTitleContainerText3}
                    >
                        Odds
                        <span className={styles.InfoIcon}>
                            <span className={styles.InfoText}>If 1000 discounts where gifted, the odds below are the amount that discount will be gifted</span>
                        </span>
                    </span>
                </div>
                <div
                    className={styles.OverviewTitle4}
                >
                    <span
                        className={styles.TopInfoTitleContainerText3}
                    >
                        Status
                    </span>
                </div>
            </div>
            {Array.isArray(coupons) && coupons.map((item, index) => (
                <div
                    className={styles.OverviewContainer}
                    key={index}
                >
                    <div
                        className={styles.OverviewContainer1}
                    >
                        <div
                            className={styles.CouponOverviewName}
                            style={
                                item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                                {}
                            }
                        >
                            <img src={Coupon} alt={"Coupon"} className={styles.CouponImage2} />
                            <span
                                className={styles.CouponOverviewText}
                            >
                               {item.couponType === 'free' ? `FREE ${item.discountItem}` :
                                item.couponType === 'bogo' && item.isAllItems === false ? `1+1 ${item.discountItem}` :
                                item.couponType === 'bogo' && item.isAllItems === true ? `1+1 All Items` :
                                item.couponType === 'percentageDiscount' ? `- ${item.discountAmount/100}%` :
                                item.couponType === 'fixedDiscount' ? `- ${item.discountAmount/100} AUD` :
                                item.couponType === 'fixedPrice' ? `${item.discountItem} @ ${item.couponFixedPrice/100} AUD` :
                                
                                ''
                               }
                            </span>
                        </div>
                    </div>
                    <div
                        className={styles.OverviewContainer2}
                    >
                        <div
                            className={styles.CardButton2Circle}
                        >
                            <img src={categoryImages[item.emoji]} alt={item.emoji} className={styles.EmojiImage} />
                        </div>
                    </div>
                    <div
                        className={styles.OverviewContainer3}
                    >
                        <button
                            className={styles.OverviewContainer3Button}
                            onClick={handleOddsClick}
                        >
                            <img src={Pencil} alt={"Pencil"} className={styles.PencilImage} />
                            <span
                                className={styles.TopInfoTitleContainerText4}
                            >
                                    <span className={styles.TopInfoTitleContainerText4Bold}>{item.couponOdds}</span>/1000
                            </span>
                        </button>
                    </div>
                    <div
                        className={styles.OverviewContainer4}
                    >
                        <span
                            className={styles.TopInfoTitleContainerText5}
                            style={
                                item.isCouponActive === 'Active'  ? {backgroundColor: '#abf1ab', color: 'green'} :
                                item.isCouponActive === 'UnderReview'  ? {backgroundColor: '#abf1ab', color: 'green'} :
                                item.isCouponActive === 'NonActive' ?  {backgroundColor: '#f4cbcb' ,color: 'red'} :
                                item.isCouponActive === 'Building' ?  {backgroundColor: '#ffffdc' ,color: 'green', fontWeight: 600} :
                                {backgroundColor: '#f4cbcb' ,color: 'red'}
                            }
                        >
                                {  item.isCouponActive === 'Active' ? `Active` :
                                item.isCouponActive === 'UnderReview' ? `Active` :
                            item.isCouponActive === 'NonActive'? `Not Active` :
                            item.isCouponActive === 'Building'? `Odds Required` :
                                
                                'Not Active'
                               }
                        </span>
                    </div>
                    <div
                        className={styles.OverviewContainer5}
                    >
                        <button
                            className={styles.CardButton3Circle}
                            onClick={handleOddsClick}
                        >
                             <img src={Cross} alt={'cross'} className={styles.CrossEmojiImage} />
                        </button>
                    </div>
                </div>
            ))}
        </div>
        <div
            className={styles.TitleContainer3}
        >
            <div
                className={styles.TitleView}
            >
                <span
                    className={styles.TitleContainerText}
                >
                    Coupon Details
                </span>
            </div>
        </div>
        </>
        :
        null
        }
        {Array.isArray(coupons) && coupons.map((item, index) => (
            <div
                key={index}
                className={styles.TopInfoContainer}
            >
                <div
                    className={styles.TopInfoTitleContainer}
                >
                    <span
                        className={styles.TopInfoTitleContainerText}
                        style={
                            item.couponType === 'free' ? {backgroundColor: '#f8e5a0', color: '#d7a342', cursor: 'auto'} :
                            item.couponType === 'bogo' ? {backgroundColor: '#8D99AE', color: '#2B2D42', cursor: 'auto'} :
                            item.couponType === 'percentageDiscount' ? {backgroundColor: '#5C5470', color: '#C98BBE', cursor: 'auto'} :
                            item.couponType === 'fixedDiscount' ? {backgroundColor: '#F4D35E', color: '#D55C5A', cursor: 'auto'} :
                            item.couponType === 'fixedPrice' ? {backgroundColor: '#ffd399', color: '#FF9F1C', cursor: 'auto'} :
                        {}
                        }
                    >
                        {index+1}. {item.couponType}
                    </span>
                </div>
                <div
                    className={styles.TopMainContainer}
                >
                    <div
                        className={styles.TopMainContainerLeft}
                    >
                        <div
                            className={styles.CardButton1}
                            style={
                                item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                            {}
                            }
                        >
                            <div
                                className={styles.CardButton1Top}
                            >
                                <img src={ImageLogo} alt={"LogoIma"} className={styles.LogoImage} />
                            </div>
                            <div
                                className={styles.CardButton1Middle}
                            >
                                <span
                                    className={styles.CardButtonMiddleText}
                                >
                                    {item.couponType === 'free' ? `FREE ${item.discountItem}` :
                                     item.couponType === 'bogo' && item.isAllItems === false ? `1+1 ${item.discountItem}` :
                                     item.couponType === 'bogo' && item.isAllItems === true ? `1+1 All Items` :
                                     item.couponType === 'percentageDiscount' ? `- ${item.discountAmount/100}%` :
                                     item.couponType === 'fixedDiscount' ? `- ${item.discountAmount/100} AUD` :
                                     item.couponType === 'fixedPrice' ? `${item.discountItem} @ ${item.couponFixedPrice/100} AUD` :
                                     
                                    ''}
                                </span>
                            </div>
                            <div
                                className={styles.CardButton1Bottom}
                            >
                                <div
                                    className={styles.CardButton1Circle}
                                    style={
                                        item.couponType === 'free' ? {backgroundColor: '#f8e5a0', cursor: 'auto'} :
                                        item.couponType === 'bogo' ? {backgroundColor: '#8D99AE', cursor: 'auto'} :
                                        item.couponType === 'percentageDiscount' ? {backgroundColor: '#5C5470', cursor: 'auto'} :
                                        item.couponType === 'fixedDiscount' ? {backgroundColor: ' #F4D35E', cursor: 'auto'} :
                                        item.couponType === 'fixedPrice' ? {backgroundColor: '#ffd399', cursor: 'auto'} :
                                    {}
                                    }
                                >
                                    <img src={Coupon} alt={"CouponIma"} className={styles.CouponImage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.TopMainContainerRight}
                        style={
                            item.couponType === 'free' ? {backgroundColor: '#f8e5a0', cursor: 'auto'} :
                            item.couponType === 'bogo' ? {backgroundColor: '#8D99AE', cursor: 'auto'} :
                            item.couponType === 'percentageDiscount' ? {backgroundColor: '#5C5470', cursor: 'auto'} :
                            item.couponType === 'fixedDiscount' ? {backgroundColor: ' #F4D35E', cursor: 'auto'} :
                            item.couponType === 'fixedPrice' ? {backgroundColor: '#ffd399', cursor: 'auto'} :
                        {}
                        }
                    >
                        <span
                            className={styles.TopInfoHeadText}
                        >
                            Discounted Item:
                        </span>
                        <span
                            className={styles.TopInfoText}
                            style={
                                item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                                {}
                            }
                        >
                            {item.isAllItems === true ? 'All Items' :
                            item.isAllItems === false && item.couponType === 'free' ? `FREE ${item.discountItem}` :
                            item.isAllItems === false ? `${item.discountItem}` :
                            ''}
                        </span>
                        {item.couponType === 'percentageDiscount' || item.couponType === 'fixedDiscount'?
                            <span
                                className={styles.TopInfoHeadText}
                            >
                                Discount Amount
                            </span>
                        :
                            <></>
                        }
                        {item.couponType === 'percentageDiscount' || item.couponType === 'fixedDiscount'?
                        <span
                            className={styles.TopInfoText}
                            style={
                                item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                            {}
                            }
                        >
                           {item.couponType === 'percentageDiscount' ? `-${item.discountAmount/100}%` :
                            item.couponType === 'fixedDiscount' ? `-${item.discountAmount/100} AUD` :
                            ''}
                        </span>
                        :
                        <></>           
                        }
                        {(item.couponType === 'percentageDiscount' || item.couponType === 'fixedDiscount') && item.isMinimumSpend === true?
                            <span
                                className={styles.TopInfoHeadText}
                            >
                                Minimum Spend Amount:
                            </span>
                        :
                            <></>
                        }
                        {(item.couponType === 'percentageDiscount' || item.couponType === 'fixedDiscount') && item.isMinimumSpend === true?
                        <span
                            className={styles.TopInfoText}
                            style={
                                item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                            {}
                            }
                        >
                            {item.couponMinimumSpend/100} AUD
                        </span>
                        :
                        <></>           
                        }
                        {item.couponType === 'bogo' ?
                            <span
                                className={styles.TopInfoHeadText}
                            >
                                Maximum Value Free Item:
                            </span>
                        :
                            <></>
                        }
                         {item.couponType === 'bogo' ?
                        <span
                            className={styles.TopInfoText}
                            style={
                                item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                            {}
                            }
                        >
                            {item.couponMinimumSpend/100} AUD
                        </span>
                        :
                        <></>           
                        }
                        {item.couponType === 'fixedPrice' ?
                            <span
                                className={styles.TopInfoHeadText}
                            >
                                Coupon Fixed Price:
                            </span>
                        :
                            <></>
                        }
                         {item.couponType === 'fixedPrice' ?
                        <span
                            className={styles.TopInfoText}
                            style={
                                item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                            {}
                            }
                        >
                            {item.couponFixedPrice/100} AUD
                        </span>
                        :
                        <></>           
                        }
                        {item.couponType === 'fixedPrice' ?
                            <span
                                className={styles.TopInfoHeadText}
                            >
                                Item Original Value:
                            </span>
                        :
                            <></>
                        }
                         {item.couponType === 'fixedPrice' ?
                        <span
                            className={styles.TopInfoText}
                            style={
                                item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                            {}
                            }
                        >
                            {item.originalValue/100} AUD
                        </span>
                        :
                        <></>           
                        }
                        <span
                            className={styles.TopInfoHeadText}
                        >
                            Rules:
                        </span>
                        <span
                            className={styles.TopInfoText}
                            style={
                                item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                            {}
                            }
                        >
                           Valid for 20 Days
                        </span>
                        <span
                            className={styles.TopInfoText}
                            style={
                                item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                            {}
                            }
                        >
                            {item.isTakeAway?
                                'Takeaway available'
                            :
                                'No Takeaway available'
                            }
                        </span>
                        <span
                            className={styles.TopInfoText}
                            style={
                                item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                            {}
                            }
                        >
                            {item.isDineIn?
                                'Dine in available'
                            :
                                'No Dine in available'
                            }
                        </span>
                        {item.terms.map((term, index) => (
                            <span
                                key={index}
                                className={styles.TopInfoText}
                                style={
                                    item.couponType === 'free' ? {backgroundColor: '#d7a342', cursor: 'auto'} :
                                    item.couponType === 'bogo' ? {backgroundColor: '#2B2D42', cursor: 'auto'} :
                                    item.couponType === 'percentageDiscount' ? {backgroundColor: '#C98BBE', cursor: 'auto'} :
                                    item.couponType === 'fixedDiscount' ? {backgroundColor: '#D55C5A', cursor: 'auto'} :
                                    item.couponType === 'fixedPrice' ? {backgroundColor: '#FF9F1C', cursor: 'auto'} :
                                {}
                                }
                            >
                                {term}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
        ))}
    </div>
);
}

export default SidebarCoupon;