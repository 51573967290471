// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StoreTagTop_Main__HmYeH {
    display: flex;
    /* background: white; */
    background: #fcfbf8;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100vw;
    height: 250px;
}
.StoreTagTop_TextDiv1Black__9SIVP {
    font-weight: 500;
    font-size: 25px;
    font-family: "Rubik";
    color:black;
    max-width: 300px;
    margin-top: 10px;
    text-align: left;
    margin-bottom: 5px;
    width: 300px;
}
.StoreTagTop_TextDiv1Gray__Jq3GF {
    font-weight: 400;
    font-size: 15px;
    font-family: "Rubik";
    color:#585b5f;
    width: 300px;
    text-align: left;
    margin-bottom: 40px;
}
.StoreTagTop_TextDiv1GrayImportant__pgaE4 {
    font-weight: 600;
    font-size: 15px;
    font-family: "Rubik";
    color:black;
    width: 300px;
    text-align: left;
}

.StoreTagTop_TextDiv2Black__HyUSH {
    font-weight: 500;
    font-size: 20px;
    font-family: "Rubik";
    color:black;
    width: 300px;
    margin-top: 20px;
    margin-bottom: 10px;
    text-align: left;
}

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 400px) {
}`, "",{"version":3,"sources":["webpack://./src/Components/StoreTagTop/StoreTagTop.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,aAAa;IACb,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;AACA;;AAEA;AACA","sourcesContent":[".Main {\n    display: flex;\n    /* background: white; */\n    background: #fcfbf8;\n    flex-wrap: nowrap;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    max-width: 100vw;\n    height: 250px;\n}\n.TextDiv1Black {\n    font-weight: 500;\n    font-size: 25px;\n    font-family: \"Rubik\";\n    color:black;\n    max-width: 300px;\n    margin-top: 10px;\n    text-align: left;\n    margin-bottom: 5px;\n    width: 300px;\n}\n.TextDiv1Gray {\n    font-weight: 400;\n    font-size: 15px;\n    font-family: \"Rubik\";\n    color:#585b5f;\n    width: 300px;\n    text-align: left;\n    margin-bottom: 40px;\n}\n.TextDiv1GrayImportant {\n    font-weight: 600;\n    font-size: 15px;\n    font-family: \"Rubik\";\n    color:black;\n    width: 300px;\n    text-align: left;\n}\n\n.TextDiv2Black {\n    font-weight: 500;\n    font-size: 20px;\n    font-family: \"Rubik\";\n    color:black;\n    width: 300px;\n    margin-top: 20px;\n    margin-bottom: 10px;\n    text-align: left;\n}\n\n@media only screen and (max-width: 600px) {\n}\n\n@media only screen and (max-width: 400px) {\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Main": `StoreTagTop_Main__HmYeH`,
	"TextDiv1Black": `StoreTagTop_TextDiv1Black__9SIVP`,
	"TextDiv1Gray": `StoreTagTop_TextDiv1Gray__Jq3GF`,
	"TextDiv1GrayImportant": `StoreTagTop_TextDiv1GrayImportant__pgaE4`,
	"TextDiv2Black": `StoreTagTop_TextDiv2Black__HyUSH`
};
export default ___CSS_LOADER_EXPORT___;
