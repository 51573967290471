import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Sidebar.module.css';
import Image from "../../Images/Sustainabite_Logo_Black.png";
import ImageLogout from "../../Images/Logout.png";

const Sidebar = ({ activeComponent, setActiveComponent, handleLogout, handleComponentClick }) => {


  return (
    <div className={styles.Sidebar}>
      <div
        className={styles.ImageContainer}
      >
        <img src={Image} alt="S_Logo" className={styles.Image} />
      </div>
      {['Dashboard', 'Coupon', 'Store', 'Settings', 'Help'].map((component) => (
        <Link
          key={component}
          to={`${component}`}
          className={`${styles.SidebarButton} ${activeComponent === component ? styles.Active : ''}`}
          onClick={() => {
            setActiveComponent(component);
            handleComponentClick(); // Close the sidebar if necessary
          }}
        >
          <div className={styles.SidebarButtonContainer}>
            <img src={require(`../../Images/${component}.png`)} alt={component} className={styles.SidebarImage} />
            <span className={`${styles.SidebarText} ${activeComponent === component ? styles.Active : ''}`}>{component}</span>
          </div>
        </Link>
      ))}
      <button 
        type='button' 
        className={styles.LogoutButtonContainer}
        onClick={handleLogout} 
        style={{
        border:'none', 
        outline: 'none',
        cursor: 'pointer',
        }}
      >
        <img src={ImageLogout} alt={"LogoutIma"} className={styles.SidebarImage} />
        <span className={styles.SidebarText}>
            Logout
        </span>
      </button>
    </div>
  );
};

export default Sidebar;

