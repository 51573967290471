import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import Home from "./Pages/Home/Home";
import Terms from "./Pages/Terms/Terms";
import Privacy from "./Pages/Privacy/Privacy";
import ContactPrivacy from "./Pages/ContactPrivacy/ContactPrivacy";

// import { getToken } from "./Helpers/Helpers";

function App() {
  return (
    <Router>
       <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Terms" element={<Terms />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/ContactPrivacy" element={<ContactPrivacy />} />
        <Route path="*" element={<Home />} />
      </Routes>
  </Router>
  );
}

export default App;
