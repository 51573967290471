import React from "react";
import styles from "./ContactPrivacy.module.css";
import TopBar from "../../Components/TopBar/TopBar";
import Footer from "../../Components/Footer/Footer";
import ContactPrivacyMiddle from "../../Components/ContactPrivacyMiddle/ContactPrivacyMiddle";

function ContactPrivacy() {
  return (
    <div className={styles.App}>
      <TopBar/>
      <ContactPrivacyMiddle/>
      <Footer/>
    </div>
  );
}

export default ContactPrivacy;