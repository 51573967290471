import React from "react";
import styles from "./TopBar.module.css";
import Image from "../../Images/Sustainabite_Logo_Black.png";
import { Link } from "react-router-dom";

function TopBar() {
  return (
   <div className={styles.Main}>
      <Link to="/">
        <img src={Image} alt="S_Logo" className={styles.Image} />
      </Link>
   </div>
  );
}

export default TopBar;