import React from "react";
import styles from "./Terms.module.css";
import TopBar from "../../Components/TopBar/TopBar";
import Footer from "../../Components/Footer/Footer";
import TermsMiddle from "../../Components/TermsMiddle/TermsMiddle";

function Terms() {
  return (
    <div className={styles.App}>
      <TopBar/>
      <TermsMiddle/>
      <Footer/>
    </div>
  );
}

export default Terms;