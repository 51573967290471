import React, {useState} from 'react';
import styles from './SidebarHelp.module.css';
import Dashboard from "../../Images/Dashboard.png";
import ClapperBoard from "../../Images/ClapperBoard.png";


function SidebarHelp() {
const [isAnswerVisible1, setIsAnswerVisible1] = useState(false);
const [isAnswerVisible2, setIsAnswerVisible2] = useState(false);
const [isAnswerVisible3, setIsAnswerVisible3] = useState(false);
const [isAnswerVisible4, setIsAnswerVisible4] = useState(false);
const [isAnswerVisible5, setIsAnswerVisible5] = useState(false);
const [isAnswerVisible6, setIsAnswerVisible6] = useState(false);
const [isAnswerVisible7, setIsAnswerVisible7] = useState(false);
const [isAnswerVisible8, setIsAnswerVisible8] = useState(false);
const [isAnswerVisible9, setIsAnswerVisible9] = useState(false);

const toggleAnswer1 = () => {
        setIsAnswerVisible1(!isAnswerVisible1);
};

const toggleAnswer2 = () => {
        setIsAnswerVisible2(!isAnswerVisible2);
};

const toggleAnswer3 = () => {
        setIsAnswerVisible3(!isAnswerVisible3);
};

const toggleAnswer4 = () => {
        setIsAnswerVisible4(!isAnswerVisible4);
};

const toggleAnswer5 = () => {
    setIsAnswerVisible5(!isAnswerVisible5);
};

const toggleAnswer6 = () => {
    setIsAnswerVisible6(!isAnswerVisible6);
};

const toggleAnswer7 = () => {
    setIsAnswerVisible7(!isAnswerVisible7);
};

const toggleAnswer8 = () => {
    setIsAnswerVisible8(!isAnswerVisible8);
};

const toggleAnswer9 = () => {
    setIsAnswerVisible9(!isAnswerVisible9);
};


  return (
    <div className={styles.Main}>
        <div className={styles.TitleContainer}>
            <span className={styles.TitleContainerText}>Help {"&"} FAQ</span>
        </div>
        <div
            className={styles.BottomContainer}
        >
            <div
                className={styles.DashboardHelpContainer}
            >
                <img src={Dashboard} alt="Dashboard_Icon" className={styles.DashboardHelpIconImage} />
                <span
                    className={styles.DashboardHelpContainerText}
                >
                    <span className={styles.DashboardHelpContainerTextBig}>Need Help?</span> contact us at <a className={styles.DashboardHelpContainerTextBig} href="mailto:nicolas@sustainabite.com.au" target="_blank" rel="noopener noreferrer">nicolas@sustainabite.com.au</a> or call us at <span className={styles.DashboardHelpContainerTextBig}>0480543610</span>
                </span>
            </div>
            {/* <div
                className={styles.DashboardHelpContainer}
            >
                <img src={ClapperBoard} alt="Dashboard_Icon" className={styles.DashboardHelpIconImage} />
                <span
                    className={styles.DashboardHelpContainerText}
                >
                    Need Help with the To do's? <a className={styles.DashboardHelpContainerTextBig} href="mailto:nicolas@sustainabite.com.au" target="_blank" rel="noopener noreferrer">Watch our video guide</a>
                </span>
            </div> */}
            {/* <div className={isAnswerVisible1 ? styles.ContainerOpenQuestion : styles.Container} onClick={toggleAnswer1}>
                <div className={styles.Question}>
                    <span className={styles.QuestionText}>
                        What is a Surprise Bag?
                    </span>
                <div className={styles.Arrow}>
                    <i className={`fa fa-chevron-right`} style={{ color: '#FF6EC7', fontSize: '24px', transform: isAnswerVisible1 ? 'rotate(90deg)' : 'rotate(0)' }} />
                </div>
                </div>
                <div className={isAnswerVisible1 ? styles.AnswerShow : styles.Answer}>
                    <span className={styles.AnswerText}>
                        A Surprise Bag is what customers buy from you on the Sustainabite app. As most stores can't predict what will be left at the end of the day, we make sure not to specify exactly what the contents of the bags will be. The contents will vary from day to day and always be a surprise to the customer.
                    </span>
                </div>
            </div>
            <div className={isAnswerVisible2 ? styles.ContainerOpenQuestion : styles.Container} onClick={toggleAnswer2}>
                <div className={styles.Question}>
                    <span className={styles.QuestionText}>
                        How do I sell my surplus food on your app?
                    </span>
                <div className={styles.Arrow}>
                    <i className={`fa fa-chevron-right`} style={{ color: '#FF6EC7', fontSize: '24px', transform: isAnswerVisible2 ? 'rotate(90deg)' : 'rotate(0)' }} />
                </div>
                </div>
                <div className={isAnswerVisible2 ? styles.AnswerShow : styles.Answer}>
                    <span className={styles.AnswerText}>
                        Once you've set up your account, your store will be visible to customers on our app. On the days you've selected, a set number of Surprise Bags will automatically be put up for sale. In the last x amount of minutes before your store closes, customers that have reserved a Surprise Bag will come to your store to pick it up. Payment is automatically handled in the app – just check for a receipt on each customer's phone and hand them their Surprise Bag.
                    </span>
                </div>
            </div>
            <div className={isAnswerVisible3 ? styles.ContainerOpenQuestion : styles.Container} onClick={toggleAnswer3}>
                <div className={styles.Question}>
                    <span className={styles.QuestionText}>
                        What goes into a Surprise Bag?
                    </span>
                <div className={styles.Arrow}>
                    <i className={`fa fa-chevron-right`} style={{ color: '#FF6EC7', fontSize: '24px', transform: isAnswerVisible3 ? 'rotate(90deg)' : 'rotate(0)' }} />
                </div>
                </div>
                <div className={isAnswerVisible3 ? styles.AnswerShow : styles.Answer}>
                    <span className={styles.AnswerText}>
                    Fill your Surprise Bags with a surprise mix of your surplus food items. We know that the amount of surplus food can vary from day to day, so this is our way of giving you the flexibility to sell genuine surplus – whatever that is. Please remember that Surprise Bags are priced at one third of their contents’ original retail value.
                    </span>
                </div>
            </div>
            <div className={isAnswerVisible4 ? styles.ContainerOpenQuestion : styles.Container} onClick={toggleAnswer4}>
                <div className={styles.Question}>
                    <span className={styles.QuestionText}>
                        What does Sustainabite cost?
                    </span>
                <div className={styles.Arrow}>
                    <i className={`fa fa-chevron-right`} style={{ color: '#FF6EC7', fontSize: '24px', transform: isAnswerVisible4 ? 'rotate(90deg)' : 'rotate(0)' }} />
                </div>
                </div>
                <div className={isAnswerVisible4 ? styles.AnswerShow : styles.Answer}>
                    <span className={styles.AnswerText}>
                    When you sell your surplus food on our marketplace, we take a small fee of 20% (excl GTS). This fee is deducted from your earnings, which means we’ll never bill you for our services. You can always cancel your account with us at any time without any commitment or extra costs.
                    </span>
                </div>
            </div>
            <div className={isAnswerVisible5 ? styles.ContainerOpenQuestion : styles.Container} onClick={toggleAnswer5}>
                <div className={styles.Question}>
                    <span className={styles.QuestionText}>
                        I don't have enough leftovers, can i cancel my upcoming reservations?
                    </span>
                <div className={styles.Arrow}>
                    <i className={`fa fa-chevron-right`} style={{ color: '#FF6EC7', fontSize: '24px', transform: isAnswerVisible5 ? 'rotate(90deg)' : 'rotate(0)' }} />
                </div>
                </div>
                <div className={isAnswerVisible5 ? styles.AnswerShow : styles.Answer}>
                    <span className={styles.AnswerText}>
                        Should you, as the day unfolds, realise that you do not have enough food left over for all the customers, it is possible to cancel reservations on the Dashboard page. Customers with cancelled reservations will be notified by email and potentially by notification and text message.
                        Please remember that reservations should only be cancelled as a last resort, and at the latest 1 hour before the pick-up time starts. If you repeatedly do not have enough food left over, we recommend that you adjust your weekly Schedule.
                    </span>
                </div>
            </div>
            <div className={isAnswerVisible6 ? styles.ContainerOpenQuestion : styles.Container} onClick={toggleAnswer6}>
                <div className={styles.Question}>
                    <span className={styles.QuestionText}>
                        How does the Payout system work?
                    </span>
                <div className={styles.Arrow}>
                    <i className={`fa fa-chevron-right`} style={{ color: '#FF6EC7', fontSize: '24px', transform: isAnswerVisible6 ? 'rotate(90deg)' : 'rotate(0)' }} />
                </div>
                </div>
                <div className={isAnswerVisible6 ? styles.AnswerShow : styles.Answer}>
                    <span className={styles.AnswerText}>
                    Once your account is set up and we have checked all your information, we pay your earnings into your bank account every month. You can find a detailed overview every month in the "Sales" tab on the left side
                    </span>
                </div>
            </div>
            <div className={isAnswerVisible7 ? styles.ContainerOpenQuestion : styles.Container} onClick={toggleAnswer7}>
                <div className={styles.Question}>
                    <span className={styles.QuestionText}>
                        What should i do when a customer comes to pick up their Surprise Bag
                    </span>
                <div className={styles.Arrow}>
                    <i className={`fa fa-chevron-right`} style={{ color: '#FF6EC7', fontSize: '24px', transform: isAnswerVisible7 ? 'rotate(90deg)' : 'rotate(0)' }} />
                </div>
                </div>
                <div className={isAnswerVisible7 ? styles.AnswerShow : styles.Answer}>
                    <span className={styles.AnswerText}>
                        Customers will come to your store during the pick-up window you have specified. When a customer arrives, ask them to show you their in-app receipt. Please make sure to check for any allergies as per your store's operational procedure. Ask the customer to swipe their in-app receipt. Payment is handled through the app, so all you need to do now is hand the Surprise Bag to the customer. Flash them a smile and enjoy that you have made a difference together.
                    </span>
                </div>
            </div>
            <div className={isAnswerVisible8 ? styles.ContainerOpenQuestion : styles.Container} onClick={toggleAnswer8}>
                <div className={styles.Question}>
                    <span className={styles.QuestionText}>
                        What do I do if a customer has allergies?
                    </span>
                <div className={styles.Arrow}>
                    <i className={`fa fa-chevron-right`} style={{ color: '#FF6EC7', fontSize: '24px', transform: isAnswerVisible8 ? 'rotate(90deg)' : 'rotate(0)' }} />
                </div>
                </div>
                <div className={isAnswerVisible8 ? styles.AnswerShow : styles.Answer}>
                    <span className={styles.AnswerText}>
                        Should the customer enquire about allergens when picking up their Surprise Bag(s), you are obliged to give them this information. If the customer does not wish to accept the Surprise Bag(s) based on this information, they have the right to refuse the bag(s). In a such case, the customer has to contact Sustainabite through the app, and we will make sure the customer is refunded.
                    </span>
                </div>
            </div>
            <div className={isAnswerVisible9 ? styles.ContainerOpenQuestion : styles.Container} onClick={toggleAnswer9}>
                <div className={styles.Question}>
                    <span className={styles.QuestionText}>
                        What if the order isn't swiped?
                    </span>
                <div className={styles.Arrow}>
                    <i className={`fa fa-chevron-right`} style={{ color: '#FF6EC7', fontSize: '24px', transform: isAnswerVisible9 ? 'rotate(90deg)' : 'rotate(0)' }} />
                </div>
                </div>
                <div className={isAnswerVisible9 ? styles.AnswerShow : styles.Answer}>
                    <span className={styles.AnswerText}>
                    The swipe function exists solely to give you as a store the opportunity to verify that the customer has an active reservation. The payment for a reservation is withdrawn after the pick-up time ends regardless of whether the reservation was swiped or not. The only cases where the payment is not withdrawn is when the reservation is cancelled by the store or by the customer.
                    </span>
                </div>
            </div> */}
        </div>
    </div>
  );
}

export default SidebarHelp;
