import React from "react";
import styles from "./StorePickerTop.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function StorePickerTop() {
  return (
   <div className={styles.Main}>
               <span className={styles.TextDiv1Black}>
                    Search Your Store
                </span>
                <span className={styles.TextDiv1Gray}>
                    Enter the name of your store in the search field below to begin setting up your account.
                </span>
   </div>
  );
}

export default StorePickerTop;