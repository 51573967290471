import React, {useState, useEffect} from "react";
import styles from "./MyMarketRegister.module.css";
import TopBar from "../../Components/TopBar/TopBar";
import Footer from "../../Components/Footer/Footer";
import axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import { API_BASE_URL } from '../../Config/Config';
import { Spinner } from "react-activity";
import { useAuthContext } from "../../Context/AuthContext";
import { setToken } from "../../Helpers/Helpers";
import "react-activity/dist/library.css";


function MyMarketRegister() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [passwordCheck, setPasswordCheck] = useState("")
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isEmailIncorrect, setIsEmailIncorrect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { setUser } = useAuthContext();

  useEffect(() => {
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const isPasswordValid = password.length >= 6;
    const isPhoneNumberValid = phoneNumber.length >= 6;
    
    setIsButtonDisabled(!(isEmailValid && isPasswordValid && isPhoneNumberValid && password === passwordCheck));
  }, [email, password, passwordCheck, phoneNumber]);

  const handleLogin = async() => {
      setIsLoading(true)
      setIsButtonDisabled(true)
    axios
  .post(`${API_BASE_URL}api/auth/local/register`, {
    username: email.toLowerCase(),
    email: email.toLowerCase(),
    password: password,
    realUsername: phoneNumber,
    type: 'restaurant',
  })
  .then(response => {
     // set the token
    setToken(response.data.jwt);

    // set the user
    setUser(response.data.user);
    const userId = response.data.user.id;
    
    navigate( `/MyMarketStorePicker/${userId}`, { replace: true })
    
    setIsLoading(false)
  })
  .catch(error => {
    setIsLoading(false)
    setIsEmailIncorrect(true)
    setEmail('')
  });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && isButtonDisabled === false) {
      // Trigger the click event of the login button when Enter is pressed.
      handleLogin();
    }
  };

  return (
    <div className={styles.App}>
      <TopBar/>
        <div
          className={styles.MiddleMyMarket}
        >
          <div
            className={styles.MMMain}
          >
            <div
              className={styles.MMLogoContainer}
            >
              <span 
                className={styles.MMLogoContainerText}
              >
                Create an account
              </span>
            </div>
            <form>
            <div
              className={styles.MMEmailContainer}
            >
              <input
                value={email}
                placeholder="Email"
                onChange={ev => setEmail(ev.target.value)}
                className={styles.MMEmailContainerText} 
                style={{
                  outline: 'none'
                }}
                /> 
            </div>
            <div
              className={styles.MMEmailContainer}
            >
              <input
                value={phoneNumber}
                placeholder="Phone Number"
                onChange={ev => setPhoneNumber(ev.target.value)}
                className={styles.MMEmailContainerText} 
                style={{
                  outline: 'none'
                }}
                /> 
            </div>
            <div
              className={styles.MMPasswordContainer}
            >
              <input
                value={password}
                placeholder="Password"
                onChange={ev => setPassword(ev.target.value)}
                type="password"
                className={styles.MMEmailContainerText} 
                style={{
                  outline: 'none'
                }}
                />
                
            </div>
            <div
              className={styles.MMPasswordContainer}
            >
              <input
                value={passwordCheck}
                placeholder="Password"
                onChange={ev => setPasswordCheck(ev.target.value)}
                type="password"
                className={styles.MMEmailContainerText} 
                onKeyPress={handleKeyPress}
                style={{
                  outline: 'none'
                }}
                />
                
            </div>
            <div
              className={styles.MMLoginContainer}
            >
                <span 
                    className={styles.MMPasswordText}
                >
                    Password has to be at least 6 characters
                </span>
                {isEmailIncorrect && (
                  <span 
                    className={styles.MMErrorContainerText}
                  >
                    Email already in use
                  </span>
                )}
            </div>
            </form>
            <div
              className={styles.MMErrorContainer}
            >
            </div>
            <div
              className={styles.MMGitContainer}
            >
                <button 
                    type='button' 
                    className={isButtonDisabled ? styles.MMLoginButton : styles.MMLoginButtonDisabled}
                    onClick={handleLogin} 
                    disabled={isButtonDisabled || isLoading }
                    style={{
                    border: isButtonDisabled ? null : 'none', 
                    outline: isButtonDisabled ? null : 'none',
                    cursor: isButtonDisabled ? 'inherit' : 'pointer',
                    }}
                >
                    {isLoading?
                        <Spinner 
                        color='#FF6EC7'
                        />
                    :
                        <span className={isButtonDisabled ? styles.MMLoginButtonText : styles.MMLoginButtonTextDisabled}>
                            Register
                        </span>
                    }
              </button>
            </div>
            <div
              className={styles.MMGitContainer1}
            >
                <Link to="/MyMarket" className={styles.Link}>
                    <span className={styles.TextDiv1Options}>
                        Go to login
                    </span>
                </Link>
            </div>
          </div>
        </div>
      <Footer/>
    </div>
  );
}

export default MyMarketRegister;